import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const Prospect = () => {
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        document.title = "Prospect";

        // Function to dynamically load a script
        const loadScript = (src, callback) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = callback;
            document.body.appendChild(script);
        };

        // Function to dynamically load a CSS file
        const loadCSS = (href) => {
            const link = document.createElement("link");
            link.href = href;
            link.rel = "stylesheet";
            link.type = "text/css";
            document.head.appendChild(link);
        };

        // Load Boxicons CSS
        loadCSS("https://cdnjs.cloudflare.com/ajax/libs/boxicons/2.1.0/css/boxicons.min.css");

        if (!window.globalPluginLoaded) {
            loadScript("https://nexus-data.io/prospect-plugin/plugin.js", () => {
                window.globalPluginLoaded = true;
            });
        } else {
            if (firstLoad) {
                setFirstLoad(false);
                window.location.reload();
            }
        }
    }, [firstLoad]);

    return (
        <div className="content-page">
            <div className="content">
                <Container fluid>
                    <companies-list data-config={`{"user_id": "${localStorage.getItem('user_id')}"}`}></companies-list>
                </Container>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            &copy; Copyright 2023 Dietary Business Intelligence LLC. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Prospect;
