import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form, Container, Col, Row, Card } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import ApiListFile from '../Components/ApiListFile';
import { fetchMethod } from '../Components/ApiHeader';
import Footer from '../Components/Footer/Footer';
import { contextVar } from '../Components/ContextVar';
import { useDebounce } from "../utils/hooks"

import "react-datepicker/dist/react-datepicker.css";
import "./Sequence.css";

const Sequences = () => {
  const { API_LIST_SEQUENCE, API_PAGINATED_SEQUENCE, API_UPDATE_GROUPS_LIST, API_DELETE_BULK, API_DROPDOWN_SCHEDULE } = ApiListFile()

  const { setnetworkError, userGroup, userCredential, setplanVisibleModal, setplanUpgradeMessage } = useContext(contextVar)
  const buttonEl = useRef(null);
  const dt = useRef(null);
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [fetchGroupsLoading, setFetchGroupsLoading] = useState(true)
  const [sequenceSaveLoading, setSequenceSaveLoading] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sequences, setSequences] = useState([]);
  const [groups, setGroups] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timestamp',
    sortOrder: -1
  });
  const [currentSequence, setCurrentSequence] = useState({
    sequence_name: '',
    group: [],
    schedule: null
  });
  const [formErrors, setFormErrors] = useState({
    sequence_name: '',
    group: '',
    schedule: ''
  });

  const debouncedSearch = useDebounce(globalFilter)

  // const [timezone, settimeZone] = useState(null)
  // const [timezoneList, settimeZoneList] = useState('UTC')


  // useEffect section
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        document.title = "Sequences";
        setFetchGroupsLoading(true);
        const response = await fetchMethod("GET", `${API_UPDATE_GROUPS_LIST}`, {});
        const schedulesResponse = await fetchMethod("GET", `${API_DROPDOWN_SCHEDULE}`, {});
        const groupOptions = response.map(group => ({ value: group._id, label: group.group_name }));
        const scheduleOptions = schedulesResponse.map(schedule => ({ value: schedule._id, label: `${schedule.name}${schedule.default ? " <default>" : ""}`, default: schedule.default }));
        setGroups(groupOptions);
        setSchedules(scheduleOptions);
        const defaultSchedule = scheduleOptions.find(schedule => schedule.default) ?? null
        if (defaultSchedule) setSelectedSchedule(defaultSchedule);
      } catch (error) {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
        }
      } finally {
        setFetchGroupsLoading(false);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchSequences();
    };

    fetchData();
  }, [lazyState, debouncedSearch]);

  const fetchSequences = async () => {
    setLoading(true);
    let gFilter = debouncedSearch.trim()
    try {
      const response = await fetchMethod("POST", `${API_PAGINATED_SEQUENCE}`, {
        page: lazyState.page,
        page_size: lazyState.rows,
        search_query: gFilter,
        sort_order: lazyState.sortOrder,
        sort_field: lazyState.sortField
      });

      setTotalRecords(response.total_count);
      setSequences(response.items);
    } catch (error) {
      console.error(error);
      if (error instanceof TypeError) {
        setnetworkError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handler function session
  const handleSubmit = async (event) => {
    const planStatus = userCredential?.plan_status; // Ensure planStatus is defined
    
    // Check the condition for Free plan or no planStatus and totalRecords is 2
    if ((planStatus === 'Free' || !planStatus) && totalRecords === 2) {
      setplanVisibleModal(true);
      setplanUpgradeMessage("You have reached the maximum number of sequence for your plan. Please upgrade your plan to add more sequences.",)
      setShowModal(false)
      return; // Prevent further execution
    }
    if (!isFormValid()) return;
    
    setSequenceSaveLoading(true);
    const url = `${API_LIST_SEQUENCE}` + (currentSequence._id ? `/${currentSequence._id}` : '');
    const method = currentSequence._id ? 'PUT' : 'POST';

    const payload = {
      sequence_name: currentSequence.sequence_name,
      group_ids: currentSequence.group,
      schedule_id: currentSequence.schedule
    };

    try {
      const response = await fetchMethod('POST', API_LIST_SEQUENCE, payload);

      if (response.status === 'error') {
        toast.current.show({
          severity: 'warn',
          summary: 'Error',
          detail: response.message,
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Sequence Added Successfully',
        });
        setShowModal(false)
        await fetchSequences()
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while processing the request. Please try again.',
      });
    } finally {
      setSequenceSaveLoading(false);
    }
  };

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const handleDelete = (id) => {
    fetchMethod("DELETE", `${API_LIST_SEQUENCE}/${id}`, {})
      .then(() => {
        fetchSequences()
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Sequence Deleted Successfully",
        });
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }

  const handleBulkDelete = async (selectionOnly) => {
    if (selectionOnly) {
      const idArray = selectedRows.map(item => item._id);
      try {
        await fetchMethod("DELETE", `${API_DELETE_BULK}`, { ids: idArray })
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Sequences have been successfully deleted",
        });
        setTotalRecords(totalRecords - idArray.length);
        setSelectedRows([]);
        fetchSequences(); // Assuming this function fetches the updated template list
        setDeleteLoading(false);
      } catch (error) {
        // Handle fetch error...
        console.error('Error during fetch:', error);
        setDeleteLoading(false);
      }
    }
  }

  const generateCSV = (data) => {
    const fields = ['sequence_name', 'group_name', 'start_date'];
    const headers = ['Sequence Name', 'Group', 'Start Date'];

    // Extract the rows
    const rows = data.map(obj => fields.map(field => {
      // If the field is 'start_date', wrap it in double quotes
      return field === 'start_date' ? `"${obj[field]}"` : obj[field];
    }).join(',')).join('\n');

    return headers.join(',') + '\n' + rows;
  };

  const exportCSV = (selectionOnly) => {
    if (selectionOnly) {
      // Generate CSV from the selectedRows
      const csvData = generateCSV(selectedRows);

      // Create a blob from the CSV
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sequences.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dt.current.exportCSV(selectionOnly);
    }
  };

  const deleteSelectedRecords = (selectionOnly) => {
    confirmDialog({
      message: "Do you want to delete these records?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",

      accept: () => {
        setDeleteLoading(true);
        handleBulkDelete(selectionOnly)
      },
      draggable: false
    });
  };

  const handleSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !sequences.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        sequences.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !sequences.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  const handleClose = () => {
    setCurrentSequence({
      sequence_name: '',
      group: [],
      schedule: null
    });
    setFormErrors({
      sequence_name: '',
      group: '',
      schedule: ''
    })
    setShowModal(false);
  }

  const handleSearchChange = (e) => {
    e.preventDefault();
    setGlobalFilter(e.target.value.trim()); // Trim if necessary
    setLoading(true);
  };

  const onSort = (event) => {
    setlazyState({ ...lazyState, sortOrder: event.sortOrder, sortField: event.sortField });
  }
  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1
    });
  };

  // Utils function session
  const isFormValid = () => {
    let isValid = true;
    let errors = {
      sequence_name: '',
      group: '',
      schedule: ''
    };

    if (!currentSequence.sequence_name.trim()) {
      errors.sequence_name = 'Sequence name is required';
      isValid = false;
    }

    if (!currentSequence.group || currentSequence.group.length === 0) {
      errors.group = 'Group is required';
      isValid = false;
    }
    const d = schedules.find(schedule => schedule.default)?.value ?? null

    if (!currentSequence.schedule) {
      errors.schedule = 'Schedule is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };
  const accept = (id) => {
    handleDelete(id)
  }

  const reject = (id) => {

  }

  // custom component
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          className="form-control-sm"
          onInput={handleSearchChange}
          placeholder="Search..."
        />
      </span>
      <div>
        <Button
          size="small"
          onClick={() => exportCSV(true)}
          label="Export"
          severity="help"
          icon="uil uil-down-arrow"
          disabled={!selectedRows || selectedRows.length === 0}
        />
        {userGroup && userGroup?.sequence && userGroup?.sequence?.bulk_sequence_delete &&
          <Button
            className="ms-2"
            size="small"
            onClick={() => deleteSelectedRecords(true)}
            label="Delete"
            severity="danger"
            icon="uil uil-trash"
            disabled={!selectedRows || selectedRows.length === 0}
            loading={deleteLoading}
          />}
      </div>
    </div>
  );

  const startDateTemplate = (rowData) => {
    // Example, update this as needed
    let startDate = rowData.start_date
    const formattedDate = startDate;
    // Determine the maximum length for display
    const maxLength = 50;
    const truncatedText = formattedDate?.length > maxLength ? `${formattedDate?.substring(0, maxLength)}...` : formattedDate;

    return (
      <>
        <Tooltip target=".tooltip-note-text">
        </Tooltip>
        <span className="tooltip-note-text" data-pr-tooltip={formattedDate}>
          {truncatedText}
        </span>
      </>
    );
  };

  const sequenceNameTemplate = (rowData) => {
    // Determine the maximum length for display
    const maxLength = 20;
    const displayText = rowData.sequence_name;
    const truncatedText = displayText.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;

    // Generate the link URL
    const url = `/sequence_details/${rowData._id}`;

    // Return the Link with Tooltip
    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <a href={url} className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </a>
      </>
    );
  };

  const groupTemplate = (rowData) => {
    const groupNamesArray = rowData?.group_name;
    const groupNamesString = Array.isArray(groupNamesArray) ? groupNamesArray.join(', ') : groupNamesArray;
    const truncatedBody = groupNamesString.length > 20
      ? `${groupNamesString.substring(0, 20)}...`
      : groupNamesString;

    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <span data-pr-tooltip={groupNamesString} className="tooltip-note-text">
          {truncatedBody}
        </span>
      </>
    )
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-text" />
        {userGroup && userGroup?.sequence && userGroup?.sequence && userGroup?.sequence?.delete_sequence &&
          <Button className='tooltip-text' ref={buttonEl} icon="uil uil-trash" rounded text aria-label="Delete" data-pr-tooltip="Delete" severity="danger"
            onClick={() => confirmdelete(rowData._id)}
          />}
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className="content-page">
          <div className="content">
            <Container fluid>
              <ConfirmDialog />
              <Toast ref={toast} />
              <Row>
                <Col>
                  <div className="page-title-box">
                    <h4 className="page-title">Sequences</h4>
                    <div className="page-title-right">
                      <div className="row g-2">
                        <div className="col-md-auto">
                          {userGroup && userGroup?.sequence && userGroup?.sequence?.create_sequence &&
                            (<>
                              {fetchGroupsLoading ? (<Skeleton width="120px" height="40px" />) : (
                                <Button
                                  label="Add Sequence"
                                  icon="uil uil-plus"
                                  size="small"
                                  onClick={() => {
                                    setCurrentSequence({
                                      sequence_name: '',
                                      group: [],
                                      schedule: schedules.find(schedule => schedule.default)?.value ?? null
                                    });
                                    setShowModal(true);
                                  }}
                                />
                              )}
                            </>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="page-title-caption-box">
                    <h6 className="page-title-caption mb-4">Automate your email outreach with timed sequences that ensure consistent follow-up and engagement.</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    {loading ? (
                      <DataTable
                        header={header}
                        value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                        tableStyle={{ minWidth: '50rem' }}
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column
                          field="sequence_name"
                          header="Sequence Name"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="group"
                          header="Group"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="start_date"
                          header="Start Date"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="Action"
                          header="Action"
                          body={() => <Skeleton ></Skeleton>}
                        />
                      </DataTable>
                    ) : (
                      <DataTable ref={dt} value={sequences}
                        lazy
                        first={lazyState.first}
                        onSelectionChange={handleSelectionChange}
                        onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                        onPage={onPage}
                        onSort={onSort}
                        sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows}
                        totalRecords={totalRecords}
                        selection={selectedRows}
                        filters={lazyState.filters}
                        selectionMode="checkbox"
                        globalFilter={globalFilter}
                        header={header}
                        removableSort paginator rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column field="sequence_name" sortable header="Sequence Name" body={sequenceNameTemplate}></Column>
                        <Column field="group_name" sortable header="Group" style={{ width: "22%" }} body={groupTemplate}></Column>
                        <Column field="start_date" sortable header="Start Date" body={startDateTemplate}></Column>
                        <Column header="Action" body={actionTemplate}></Column>
                      </DataTable>
                    )}
                  </Card>
                </Col>
              </Row>
              {/* Form section */}
              <Dialog header="Add Sequence"
                visible={showModal}
                onHide={handleClose}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                draggable={false}
                resizable={false}
                className="addSequenceModal"
              >
                <Form>
                  <Form.Group controlId="formSequenceName">
                    <Form.Label>Sequence Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Sequence Name" value={currentSequence.sequence_name} onChange={(e) => {
                      if (e.target.value && e.target.value.startsWith(' ')) {
                        return;  // If it starts with a space, do not update the state
                      } else { setCurrentSequence({ ...currentSequence, sequence_name: e.target.value }) }
                    }} maxLength={150} />
                    {formErrors.sequence_name && <Form.Text className="text-danger">{formErrors.sequence_name}</Form.Text>}
                  </Form.Group>
                  <Form.Group controlId="formGroup">
                    <Form.Label>Group <span className="text-danger">*</span></Form.Label>
                    <CreatableSelect
                      isMulti
                      onChange={(e) => {
                        setCurrentSequence((prev) => ({
                          sequence_name: prev.sequence_name,
                          schedule: prev.schedule,
                          group: e.map(group => group.value)
                        }))
                      }
                      }
                      options={groups}
                    />
                    {formErrors.group && <Form.Text className="text-danger">{formErrors.group}</Form.Text>}
                  </Form.Group>
                  <Form.Group controlId="formSchedule">
                    <Form.Label>Schedule <span className="text-danger">*</span></Form.Label>
                    <Select
                      value={selectedSchedule}
                      options={schedules}
                      onChange={(selectedOption) => {
                        setSelectedSchedule(selectedOption);
                        setCurrentSequence({
                          ...currentSequence,
                          schedule: selectedOption ? selectedOption.value : null
                        });
                      }}
                    />
                    {formErrors.schedule && <Form.Text className="text-danger">{formErrors.schedule}</Form.Text>}
                  </Form.Group>
                </Form>
                <div className='d-flex justify-content-end mt-3'>
                  <Button onClick={handleClose} size="small" className='me-2' outlined>Close</Button>
                  <Button
                    onClick={handleSubmit}
                    size="small"
                    icon="pi pi-check"
                    label='Save'
                    loading={sequenceSaveLoading}
                    className="ms-2" />
                </div>
              </Dialog>
            </Container>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Sequences;
