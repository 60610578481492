// import React, { useContext, useEffect, useState } from 'react';
// import { TabView, TabPanel } from 'primereact/tabview';
// import MainDataTable from '../../Common/DataTable/MainDataTable';
// import ApiListFile from '../../Components/ApiListFile';
// import ApiHeader, { fetchMethod } from '../../Components/ApiHeader';
// import { Skeleton } from 'primereact/skeleton';
// import { contextVar } from '../../Components/ContextVar';

// const RecipientActivities = (props) => {
//     const tooltipStyles = `
//     .custom-tooltip {
//         animation: fadeIn 0.2s ease-in;
//     }

//     @keyframes fadeIn {
//         from { opacity: 0; }
//         to { opacity: 1; }
//     }
// `;

//     const sequenceId = props?.sequenceId
//     const { API_EMAIL_SEQUENCE_STATS } = ApiListFile()
//     const { setnetworkError, userCredential } = useContext(contextVar)

//     const [activeTab, setActiveTab] = useState(0);
//     const [statsOfRecipient, setstatsOfRecipient] = useState('')
//     const [loading, setloading] = useState(true)
//     const [tooltipContent, setTooltipContent] = useState('');
//     const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
//     const [showTooltip, setShowTooltip] = useState(false);

//     const planStatus = userCredential?.plan_status;
//     console.log("planstatus", planStatus)
//     const tabHeaders = [
//         { label: 'Scheduled', count: (Math.max(statsOfRecipient?.scheduled || 0)), disabled: false },
//         { label: 'Delivered', count: (statsOfRecipient?.sent || 0), disabled: false },
//         {
//             label: 'Opened',
//             count: planStatus === 'Free' || !planStatus
//                 ? <i className="pi pi-lock"></i>
//                 : statsOfRecipient?.opened || 0,
//             disabled: planStatus === 'Free' || !planStatus,
//             tooltipContent: 'Upgrade to access email open tracking'
//         },
//         { label: 'Not Opened', count: (statsOfRecipient?.not_opened || 0), disabled: false },
//         { label: 'Replied', count: (statsOfRecipient?.replied || 0), disabled: false },
//         { label: 'Unsubscribed', count: (statsOfRecipient?.opt_out || 0), disabled: false },
//         { label: 'Bounced', count: (statsOfRecipient?.bounced || 0), disabled: false },
//         {
//             label: 'Clicked',
//             count: planStatus === 'Free' || !planStatus
//                 ? <i className="pi pi-lock"></i>
//                 : statsOfRecipient?.clicked || 0,
//             disabled: planStatus === 'Free' || !planStatus,
//             tooltipContent: 'Upgrade to access link click tracking'
//         }
//     ];

//     const handleTabChange = (event) => {
//         setActiveTab(event.index);
//     };

//     const handleMouseEnter = (event, item) => {
//         if (item.disabled && item.tooltipContent) {
//             const rect = event.currentTarget.getBoundingClientRect();
//             setTooltipContent(item.tooltipContent);
//             setTooltipPosition({
//                 x: rect.left + (rect.width / 2),
//                 y: rect.bottom + 5
//             });
//             setShowTooltip(true);
//         }
//     };

//     const handleMouseLeave = () => {
//         setShowTooltip(false);
//     };

//     useEffect(() => {
//         setloading(true)
//         fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${sequenceId}`, {})
//             .then((data) => {
//                 setstatsOfRecipient(data);
//                 setloading(false)
//             })
//             .catch((error) => {
//                 console.error(error);
//                 if (error instanceof TypeError) {
//                     setnetworkError(true)
//                     setloading(false);
//                     return;
//                 }
//             });
//     }, [])

//     return (
//         <div className='Recipient-Activities-tabs'>
//             <style>{tooltipStyles}</style>
//             {showTooltip && (
//                 <div
//                     className="custom-tooltip"
//                     style={{
//                         position: 'fixed',
//                         left: `${tooltipPosition.x}px`,
//                         top: `${tooltipPosition.y}px`,
//                         transform: 'translateX(-50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         color: 'white',
//                         padding: '4px 8px',
//                         borderRadius: '4px',
//                         fontSize: '12px',
//                         zIndex: 1000,
//                         pointerEvents: 'none'
//                     }}
//                 >
//                     {tooltipContent}
//                 </div>
//             )}

//             {loading ? (
//                 <>
//                     <div className="skeleton-container" style={{ display: 'flex' }}>
//                         {tabHeaders.map((header, index) => (
//                             <div key={index} style={{ marginRight: '20px' }}>
//                                 <Skeleton height="20px" width="100px" />
//                             </div>
//                         ))}
//                     </div>
//                     <div className='mt-4'>
//                         <MainDataTable />
//                     </div>
//                 </>
//             ) : (
//                 <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
//                     {tabHeaders?.map((items, index) => (
//                         <TabPanel
//                             key={index}
//                             disabled={items.disabled}
//                             header={
//                                 <div
//                                     className={`tab-header ${items.disabled ? 'disabled-tab' : ''}`}
//                                     onMouseEnter={(e) => handleMouseEnter(e, items)}
//                                     onMouseLeave={handleMouseLeave}
//                                     style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
//                                 >
//                                     {items?.label}
//                                     <span className='Recipient-tab-header-span mr-2'>
//                                         {items?.count}
//                                     </span>
//                                 </div>
//                             }
//                         >
//                             <MainDataTable sequenceId={sequenceId} activeTab={index} />
//                         </TabPanel>
//                     ))}
//                 </TabView>
//             )}
//         </div>
//     );
// };

// export default RecipientActivities;


import React, { useContext, useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import MainDataTable from '../../Common/DataTable/MainDataTable';
import ApiListFile from '../../Components/ApiListFile';
import ApiHeader, { fetchMethod } from '../../Components/ApiHeader';
import { Skeleton } from 'primereact/skeleton';
import { contextVar } from '../../Components/ContextVar';

const RecipientActivities = (props) => {
    const tooltipStyles = `
    .custom-tooltip {
        animation: fadeIn 0.2s ease-in;
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    .disabled-tab {
        cursor: not-allowed;
        opacity: 0.6;
    }
`;

    const sequenceId = props?.sequenceId;
    const { API_EMAIL_SEQUENCE_STATS } = ApiListFile();
    const { setnetworkError, userCredential } = useContext(contextVar);

    const [activeTab, setActiveTab] = useState(0);
    const [statsOfRecipient, setstatsOfRecipient] = useState('');
    const [loading, setloading] = useState(true);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);

    const planStatus = userCredential?.plan_status;

    const tabHeaders = [
        { label: 'Scheduled', count: (Math.max(statsOfRecipient?.scheduled || 0)), disabled: false },
        { label: 'Delivered', count: (statsOfRecipient?.sent || 0), disabled: false },
        {
            label: 'Opened',
            count: planStatus === 'Free' || !planStatus
                ? <i className="pi pi-lock"></i>
                : statsOfRecipient?.opened || 0,
            disabled: planStatus === 'Free' || !planStatus,
            tooltipContent: 'Upgrade to access email open tracking'
        },
        { label: 'Not Opened', count: (statsOfRecipient?.not_opened || 0), disabled: false },
        { label: 'Replied', count: (statsOfRecipient?.replied || 0), disabled: false },
        { label: 'Unsubscribed', count: (statsOfRecipient?.opt_out || 0), disabled: false },
        { label: 'Bounced', count: (statsOfRecipient?.bounced || 0), disabled: false },
        {
            label: 'Clicked',
            count: planStatus === 'Free' || !planStatus
                ? <i className="pi pi-lock"></i>
                : statsOfRecipient?.clicked || 0,
            disabled: planStatus === 'Free' || !planStatus,
            tooltipContent: 'Upgrade to access link click tracking'
        }
    ];

    const handleTabChange = (event) => {
        setActiveTab(event.index);
    };

    const handleMouseEnter = (event, item) => {
        if (item.disabled && item.tooltipContent) {
            const rect = event.currentTarget.getBoundingClientRect();
            setTooltipContent(item.tooltipContent);
            setTooltipPosition({
                x: rect.left + (rect.width / 2),
                y: rect.bottom + 5
            });
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    useEffect(() => {
        setloading(true);
        fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${sequenceId}`, {})
            .then((data) => {
                setstatsOfRecipient(data);
                setloading(false);
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true);
                    setloading(false);
                    return;
                }
            });
    }, []);

    return (
        <div className='Recipient-Activities-tabs'>
            <style>{tooltipStyles}</style>
            {showTooltip && (
                <div
                    className="custom-tooltip"
                    style={{
                        position: 'absolute',
                        left: `${tooltipPosition.x}px`,
                        top: `${tooltipPosition.y}px`,
                        transform: 'translate(-50%, 10px)', // Adjust for center positioning
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        padding: '6px 10px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        zIndex: 1000,
                        pointerEvents: 'none',
                        whiteSpace: 'nowrap', // Prevent line breaks
                    }}
                >
                    {tooltipContent}
                </div>
            )}
            {loading ? (
                <>
                    <div className="skeleton-container" style={{ display: 'flex' }}>
                        {tabHeaders.map((header, index) => (
                            <div key={index} style={{ marginRight: '20px' }}>
                                <Skeleton height="20px" width="100px" />
                            </div>
                        ))}
                    </div>
                    <div className='mt-4'>
                        <MainDataTable />
                    </div>
                </>
            ) : (
                <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
                    {tabHeaders.map((items, index) => (
                        <TabPanel
                            key={index}
                            disabled={items.disabled}
                            header={
                                <div
                                    className={`tab-header ${items.disabled ? 'disabled-tab' : ''}`}
                                    onMouseEnter={(e) => items.disabled && handleMouseEnter(e, items)}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        position: 'relative', // Ensures correct relative positioning
                                    }}
                                >
                                    <span >
                                        {items.label}
                                    </span>
                                    <span className='Recipient-tab-header-span mr-2'>
                                        {items.count}
                                    </span>
                                </div>
                            }
                        >
                            <MainDataTable sequenceId={sequenceId} activeTab={index} />
                        </TabPanel>
                    ))}
                </TabView>
            )}
        </div>
    );
};

export default RecipientActivities;