import React from "react";
import "../assets/css/style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AWS from "aws-sdk";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { min } from "lodash";
import { getS3Credentials } from "../Components/SecretsManager";

class UploadAdapter {
  constructor(loader, uploadToS3) {
    this.loader = loader;
    this.uploadToS3 = uploadToS3;
  }

  upload() {
    return this.loader.file
      .then((file) => this.uploadToS3(file))
      .then((response) => ({
        default: response.Location,
      }))
      .catch((error) => {
        console.error("Image upload failed:", error);
        throw error;
      });
  }

  abort() {
    // Handle aborting the upload if necessary
  }
}


function CustomPastePlugin(editor) {
  editor.plugins.get('Clipboard').on('inputTransformation', (evt, data) => {
    const files = Array.from(data.dataTransfer.files);
    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        const loader = editor.plugins.get('FileRepository').createLoader(file);
        if (loader) {
          loader.upload();
        }
      }
    });
  });
}

class TemplateEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      showHtmlInput: false,
      rawHtml: "",
      editorType: "visual",
      showPreview: false,
      attachedFiles: [],
      displayDialog: false,
      dialogMessage: "",
      showModal: false,
      modalHtmlContent: "",
      selectedText: "",
    };
    this.editorRef = React.createRef();

    //   AWS.config.update({
    //     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    //     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    //   });
    // }




    // uploadFileToS3 = async (file) => {
    //   const s3 = new AWS.S3();
    //   const params = {
    //     Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    //     Key: file.name,
    //     Body: file,
    //   };
    //   return s3.upload(params).promise();
  };


  async componentDidMount() {
    try {
      const credentials = await getS3Credentials();
      console.log("AWS Credentials:", credentials);
      if (credentials) {
        AWS.config.update({
          accessKeyId: credentials.aws_access_key_id,
          secretAccessKey: credentials.aws_secret_access_key,
          region: credentials.region_name
        });
        this.setState({ awsCredentials: credentials });
      } else {
        console.error("Failed to fetch AWS credentials");
      }
    } catch (error) {
      console.error("Error initializing AWS credentials:", error);
    }
  }


  uploadFileToS3 = async (file) => {
    if (!this.state.awsCredentials) {
      console.error("AWS credentials not available");
      return;
    }

    const s3 = new AWS.S3({
      accessKeyId: this.state.awsCredentials.aws_access_key_id,
      secretAccessKey: this.state.awsCredentials.aws_secret_access_key,
      region: this.state.awsCredentials.region_name
    });

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };
    return s3.upload(params).promise();
  };

  showDialog = (message) => {
    this.setState({ displayDialog: true, dialogMessage: message });
  };

  hideDialog = () => {
    this.setState({ displayDialog: false, dialogMessage: "" });
  };

  toggleModal = (event) => {
    if (event) {
      event.preventDefault(); // Only prevent default if an event is provided
    }

    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      // modalHtmlContent: "", // Reset modal content on open
    }));
  };

  handleModalChange = (event) => {
    this.setState({ modalHtmlContent: event.target.value });
  };

  submitHtmlToEditor = () => {
    const { modalHtmlContent } = this.state;

    // Check if the editor instance exists and set data directly
    if (this.editorInstance) {
      this.editorInstance.setData(modalHtmlContent); // Update CKEditor content
    }

    this.setState({
      editorHtml: modalHtmlContent, // Update state to reflect new HTML
      showModal: false, // Close modal
    });
  };
  imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", ""); // Allow multiple file selection
    input.click();

    input.onchange = async () => {
      const files = Array.from(input.files); // Convert FileList to Array
      if (files.length === 0) return;

      for (const file of files) {
        console.log("file selected:", file);

        // Validate file type
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!validImageTypes.includes(file.type)) {
          this.showDialog("Please select a valid image file (JPEG, PNG, GIF).");
          continue; // Skip invalid file types
        }

        // Validate file size
        if (file.size > 1 * 1024 * 1024) { // Max size: 1 MB
          this.showDialog("Image is too large (max 1MB).");
          continue; // Skip large files
        }

        try {
          // Upload the file to S3
          const s3Response = await this.uploadFileToS3(file);
          const s3Url = s3Response.Location;
          console.log("Uploaded to S3:", s3Url);

          // Insert the image into the editor
          this.editorInstance.model.change((writer) => {
            const imageElement = writer.createElement("imageBlock", {
              src: s3Url,
              alt: file.name,
            });

            // Insert the image at the current selection and then move the selection forward
            const insertPosition = this.editorInstance.model.document.selection.getFirstPosition();
            writer.insert(imageElement, insertPosition);

            // Move the selection after the inserted image to prepare for the next image insertion
            writer.setSelection(writer.createPositionAfter(imageElement));
          });
        } catch (error) {
          console.error("Error uploading image to S3:", error);
          this.showDialog("There was an error uploading your image. Please try again.");
        }
      }
    };
  };

  attachFileHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*");
    input.setAttribute("multiple", "");
    input.click();

    input.onchange = async () => {
      const files = Array.from(input.files);
      if (files.length === 0) return;

      const newFiles = [];
      try {
        for (const file of files) {
          const s3Response = await this.uploadFileToS3(file);
          const s3Url = s3Response.Location;
          newFiles.push({ name: file.name, url: s3Url });
        }

        this.setState((prevState) => ({
          attachedFiles: [...prevState.attachedFiles, ...newFiles],
        }));

        this.props.onFileUpload(files);
      } catch (error) {
        console.error("Error uploading files to S3:", error);
        this.showDialog("There was an error uploading your files. Please try again.");
      }
    };
  };

  handleChange = (event, editor) => {
    const content = editor.getData();
    this.setState({ editorHtml: content });
    this.props.onEditorChange(content.trim());
  };

  togglePreview = () => {
    this.setState((prevState) => ({ showPreview: !prevState.showPreview }));
  };

  handleImageAlignment = (alignment) => {
    if (this.editorInstance) {
      const selection = this.editorInstance.model.document.selection;
      const imageElement = selection.getSelectedElement();

      if (imageElement && imageElement.name === 'image') {
        this.editorInstance.execute('imageStyle', { value: `align${alignment}` });
      }
    }
  };

  addCustomStyles = () => {
    const style = document.createElement('style');
    style.innerHTML = `
      .ck-content .image.image-style-align-left {
        float: left;
        margin-right: 20px;
      }
      .ck-content .image.image-style-align-center {
        margin-left: auto;
        margin-right: auto;
      }
      .ck-content .image.image-style-align-right {
        float: right;
        margin-left: 20px;
      }
      .ck-content ul {
    list-style-type: disc !important;
    margin-left: 1.5em !important;
    padding-left: 1em !important;
  }
  .ck-content ol {
    list-style-type: decimal !important;
    margin-left: 1.5em !important;
    padding-left: 1em !important;
  }
  .ck-content ul li,
  .ck-content ol li {
    display: list-item !important;
    margin-bottom: 0.5em !important;
  }
    `;
    document.head.appendChild(style);
  };


  handleSelectionChange = (event, editor) => {
    const selection = editor.model.document.selection;
    const range = selection.getFirstRange();

    if (range) {
      const selectedFragment = editor.data.stringify(
        editor.model.getSelectedContent(selection)
      );

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = selectedFragment;
      const selectedText = tempDiv.textContent.trim();
      if (selectedText) {
        this.setState({ selectedText });

        // Send selected text to parent component if provided
        if (this.props.onTextSelection) {
          this.props.onTextSelection(selectedText);
        }

        // Copy to clipboard
        navigator.clipboard.writeText(selectedText).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      }
    }
  };

  render() {
    const { template_body } = this.props;
    const initialData = template_body || "";

    return (
      <div>
        {this.state.editorType === "visual" ? (
          <div className="editor-container">
            <div className="editor-header">
              <i
                className="pi pi-images cursor-pointer"
                style={{ fontSize: '1.3rem' }}
                onClick={this.imageHandler}
              />
              <i
                className="pi pi-paperclip cursor-pointer"
                style={{ fontSize: '1.3rem', marginLeft: '0.8rem' }}
                onClick={this.attachFileHandler}
              />
              <i
                className="pi pi-code cursor-pointer"
                style={{ fontSize: '1.3rem', marginLeft: '0.8rem' }}
                onClick={this.toggleModal}
              />

            </div>

            <CKEditor
              ref={this.editorRef}
              editor={ClassicEditor}
              data={initialData}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "insertTable",
                  "mediaEmbed",
                  "alignment",
                  "imageStyle:alignLeft",
                  "imageStyle:alignCenter",
                  "imageStyle:alignRight",
                  "undo",
                  "redo",
                ],
                image: {
                  toolbar: [
                    'imageStyle:alignLeft',
                    'imageStyle:alignCenter',
                    'imageStyle:alignRight',
                    '|',
                    'imageTextAlternative'
                  ],
                  styles: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight'
                  ],
                  resizeOptions: [
                    {
                      name: 'imageResize:original',
                      value: null,
                      label: 'Original'
                    },
                    {
                      name: 'imageResize:50',
                      value: '50',
                      label: '50%'
                    },
                    {
                      name: 'imageResize:75',
                      value: '75',
                      label: '75%'
                    }
                  ],
                },
                alignment: {
                  options: ['left', 'center', 'right']
                }
              }}
              onReady={(editor) => {
                this.editorInstance = editor;

                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                  return new UploadAdapter(loader, this.uploadFileToS3);
                };

                editor.plugins.get('Clipboard').on('inputTransformation', (evt, data) => {
                  const files = Array.from(data.dataTransfer.files);
                  files.forEach((file) => {
                    if (file.type.startsWith("image/")) {
                      const loader = editor.plugins.get('FileRepository').createLoader(file);
                      if (loader) {
                        loader.upload();
                      }
                    }
                  });
                });
                this.addCustomStyles();

                editor.editing.view.document.on('change:data', () => {
                  this.setState({ editorHtml: editor.getData() });
                });
                editor.model.document.selection.on('change:range', () => {
                  this.handleSelectionChange(null, editor);
                });
                editor.editing.view.change(writer => {
                  writer.setStyle(
                    'height',
                    '300px',
                    editor.editing.view.document.getRoot()
                  );
                });

                // Add custom buttons to toolbar
                editor.ui.componentFactory.add('imageUpload', (locale) => {
                  const button = editor.ui.buttonFactory.createButton({
                    label: 'Insert Image',
                    withText: true,
                    tooltip: true,
                  });
                  button.on('execute', this.imageHandler);
                  return button;
                });

                editor.ui.componentFactory.add('attachFile', (locale) => {
                  const button = editor.ui.buttonFactory.createButton({
                    label: 'Attach File',
                    withText: true,
                    tooltip: true,
                  });
                  button.on('execute', this.attachFileHandler);
                  return button;
                });
              }}
              onChange={this.handleChange}
            />

            {/* {this.state.attachedFiles.length > 0 && (
              <div className="attached-files mt-3">
                <h6>Attached Files:</h6>
                <ul className="list-unstyled">
                  {this.state.attachedFiles.map((file, index) => (
                    <li key={index}>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
          </div>
        ) : (
          <div>
            {/* <textarea
              className="form-control mb-3"
              value={template_body}
              onChange={(e) => this.handleChange(e, { getData: () => e.target.value })}
              style={{ width: "100%", height: "100px" }}
              placeholder="Paste your HTML here"
            /> */}
            <button onClick={this.togglePreview} type="button">
              Toggle Preview
            </button>
            <div
              className="html-preview"
              dangerouslySetInnerHTML={{
                __html: this.editorRef.current?.editorInstance.getData()
              }}
            />
          </div>
        )}
        {/* Modal for pasting HTML code */}
        <Dialog
          header="Source Code"
          visible={this.state.showModal}
          onHide={this.toggleModal}
          draggable={false}
          footer={
            <div>
              <Button type="button" label="Cancel" onClick={this.toggleModal} className="p-button-text" />
              <Button type="button" label="Submit" onClick={this.submitHtmlToEditor} />
            </div>
          }
          style={{
            minWidth: '300px',
            minHeight: '200px',
            width: '50vw',
            height: '50vh',
          }}
        >
          <textarea
            className="form-control"
            value={this.state.modalHtmlContent}
            onChange={this.handleModalChange}
            style={{ width: "100%", height: "350px" }}
            placeholder="Paste your HTML here"
          />
        </Dialog>
        <Dialog
          header="Alert"
          visible={this.state.displayDialog}
          onHide={this.hideDialog}
        >
          <p>{this.state.dialogMessage}</p>
        </Dialog>
      </div>
    );
  }
}

export default TemplateEditor;


