import React from 'react';
import './Dialpad.scss';

const Dialpad = ({ onKeyPress }) => {
  const keys = [
    { number: '1', letters: '' },
    { number: '2', letters: 'ABC' },
    { number: '3', letters: 'DEF' },
    { number: '4', letters: 'GHI' },
    { number: '5', letters: 'JKL' },
    { number: '6', letters: 'MNO' },
    { number: '7', letters: 'PQRS' },
    { number: '8', letters: 'TUV' },
    { number: '9', letters: 'WXYZ' },
    { number: '*', letters: '' },
    { number: '0', letters: '+' },
    { number: '#', letters: '' }
  ];

  const handleKeyClick = (key) => {
    if (onKeyPress) {
      onKeyPress(key);
    }
  };

  return (
    <div className="dialpad">
      <div className="dialpad-grid">
        {keys.map((key) => (
          <button
            key={key.number}
            className="dialpad-key"
            onClick={() => handleKeyClick(key.number)}
          >
            <div className="number">{key.number}</div>
            <div className="letters">{key.letters}</div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dialpad;
