export default function ApiListFile() {

    let baseUrl = process.env.REACT_APP_API_HOST

    let apiList = {
        API_SAVE_TOURSTATUS: `${baseUrl}/save_tourstatus`,
        API_GET_USER_CREDENTIAL_DATA: `${baseUrl}/get_user_credentials`,
        API_OUTGOING_CALL_TOKEN: `${baseUrl}/token`, //Outgoing Call
        API_TWILIO_CALLING: `${baseUrl}/update_twilio_config`,
        API_TWILIO_CALL_REJECTION: `${baseUrl}/reject-call`,
        API_GET_CALL_RECORD: `${baseUrl}/get-call-recording`,
        API_TRANSCRIPT_DATA: `${baseUrl}/fetch-transcription`,
        API_TRANSLATE_TEXT: `${baseUrl}/translate`,
        API_ADD_CALL_DISPOSITION: `${baseUrl}/add-call-disposition`,
        API_LIST_CALL_RECORD: `${baseUrl}/get-call-list`,
        API_CALL_RECORD_COUNT: `${baseUrl}/get-call-disposition-counts`,
        API_GET_PARENT_SID: `${baseUrl}/parent-sid`,
        API_TWILIO_NUMBER_LIST: `${baseUrl}/get_available_numbers_list`,
        API_CLAIM_TWILIO_NUMBER: `${baseUrl}/claim_number`,
        API_GET_PURCHASE_NUMBER: `${baseUrl}/get_purchase_number`,
        API_FORGOT_PASSWORD: `${baseUrl}/forgot-password`, //Forgot Password
        API_RESET_PASSWORD: `${baseUrl}/reset-password`, //Reset Password
        API_GOOGLE_OAUTH: `${baseUrl}/google_oauth`,
        API_TASK_LIST: `${baseUrl}/tasks`, 
        API_PENDING_TASK_PAGINATED: `${baseUrl}/pending_tasks`, 
        API_COMPLETED_TASK_PAGINATED: `${baseUrl}/completed_tasks`, 
        API_GET_DASHBOARD_STATUS: `${baseUrl}/dashboard_stats`, 
        // API_GET_LAST_DASHBOARD_STATUS: `${baseUrl}/dashboard_stats`, 
        API_GET_RECIPIENT_LIST: `${baseUrl}/recipient`, 
        API_PAGINATED_RECIPIENT: `${baseUrl}/recipients`, 
        API_COMPLETE_TASK: `${baseUrl}/complete_task`,
        API_GET_TEAMS_LIST: `${baseUrl}/teams`, 
        API_ADD_TEAMS: `${baseUrl}/team`,
        API_DELETE_BULK_TEAM: `${baseUrl}/bulk_team`,
        API_GET_REPORT_DROPDOWN: `${baseUrl}/reports/user_dropdown`,
        API_GET_TEAM_MEMBERS_REPORT: `${baseUrl}/reports/members_data`,
        API_CAMPAIGN: `${baseUrl}/campaign`, 
        API_TEMPLATE: `${baseUrl}/template`, 
        API_PAGINATED_TEMPLATE: `${baseUrl}/templates`, 
        API_BULK_DELETE: `${baseUrl}/bulk_template`, 
        API_SEND_EMAIL: `${baseUrl}/send_email`, 
        API_DISPLAY_SIGNATURE: `${baseUrl}/display_signature`, 
        API_EMAIL_DETAILS: `${baseUrl}/email_details`, 
        API_FOLDER_LIST: `${baseUrl}/folders`, 
        API_SEND_REPLY: `${baseUrl}/send_reply`, 
        API_SYNC_MAILBOX: `${baseUrl}/sync_mailbox`, 
        API_INBOX: `${baseUrl}/inbox`, 
        API_DELETE_INBOX_MAIL: `${baseUrl}/delete_inbox_mail`, 
        API_USERS: `${baseUrl}/users`, 
        API_USERS_BULK_UPLOAD: `${baseUrl}/user/bulk`, 
        API_GET_AND_DISPLAY_USERS : `${baseUrl}/get_and_display_users`,
        API_DELETE_BULK_USER: `${baseUrl}/bulk_user`,
        API_POST_USER: `${baseUrl}/user`, 
        API_POST_USER_TIMEZONES: `${baseUrl}/fetch_default_schedule`,
        API_DISPLAY_STAGE: `${baseUrl}/stages`,
        API_STAGE: `${baseUrl}/stage`,
        API_ACTIVITY_LOGS: `${baseUrl}/activitylogs`,
        API_SAVE_TOKENS: `${baseUrl}/save_tokens`,
        API_UNLINK_MAILBOX: `${baseUrl}/unlink_mailbox`,
        API_CHANGE_PASSWORD: `${baseUrl}/change_password`,
        API_MAKE_DEFAULT: `${baseUrl}/make_default`,
        API_SIGNATURE: `${baseUrl}/signature`,
        API_UNSUBSCRIBE: `${baseUrl}/unsubscribe`,
        API_GET_GROUPS_LIST: `${baseUrl}/groups`, 
        API_UPDATE_GROUPS_LIST: `${baseUrl}/group`, 
        API_DELETE_GROUPS: `${baseUrl}/bulk_group`,
        API_MOVE_GROUPS: `${baseUrl}/move-group-recipients`,
        API_PEOPLE: `${baseUrl}/people`, 
        API_GET_USER_TIMEZONE: `${baseUrl}/user_timezones`, 
        API_GET_AND_DISPLAY_CSV: `${baseUrl}/get_and_displaycsv`, 
        API_BULK_RECIPIENT_UPLOAD: `${baseUrl}/recipients/bulk`, 
        API_SEND_EMAIL: `${baseUrl}/send_email`, 
        API_GET_BULK_RECIPIENT: `${baseUrl}/bulk_recipients`,
        API_DISPLAY_SIGNATURE: `${baseUrl}/display_signature`,
        API_GET_TASKS: `${baseUrl}/tasks`,
        API_DELETE_BULK_TASK: `${baseUrl}/bulk_task`,
        API_GET_COMPLETE_TASK: `${baseUrl}/complete_task`,
        API_GET_REPORT_USERS: `${baseUrl}/fetch_report_users`,
        API_GET_SALES_EXECUTION_REPORT: `${baseUrl}/sales_execution_report`,
        API_ZYLERERP_ASSIGNED_TO_STAFF: "https://api.zylererp.com/api/sales/get_company_assigned_to_staff",
        API_ZYLERERP_OUTBOUND_VS_ANSWERED: "https://api.zylererp.com/api/sales/get_company",
        API_GET_SCHEDULE: `${baseUrl}/schedule`,
        API_GET_CAMPAIGN: `${baseUrl}/campaign`,
        API_LIST_SEQUENCE: `${baseUrl}/sequence`,
        API_PAGINATED_SEQUENCE: `${baseUrl}/sequences`,
        API_DELETE_BULK: `${baseUrl}/bulk_sequence`,
        API_GET_SEQUENCE_NAME: `${baseUrl}/get_sequence_name`,
        API_SEQUENCE_STEPS: `${baseUrl}/sequence_steps`,
        API_DASHBOARD_LEAD_COUNT: `${baseUrl}/dashboard_leads_count`,
        API_DASHBOARD_GET_YEARWISE_LEADS: `${baseUrl}/get_yearwise_leads`,
        API_DASHBOARD_GET_SEQUENCEWISE_EMAIL_DETAILS: `${baseUrl}/get_sequencewise_email_details`,
        API_DASHBOARD_EMAIL_OPENED_BY_TIME_CHART: `${baseUrl}/email_opened_by_time_chart`,
        API_DASHBOARD_TOTAL_CONTACTS_PER_SEQUENCE: `${baseUrl}/total_contacts_per_sequence`,
        API_EMAIL_SEQUENCE_STATS: `${baseUrl}/get_email_statistics_by_sequence`,
        API_SEQUENCE_EMAIL: `${baseUrl}/sequence_emails`,
        API_GENERATE_EMAIL: `${baseUrl}/generate_email`,
        API_SUGGESTION_EMAIL: `${baseUrl}/reply_suggestions`,
        API_TRIGGER_CONTACT: `${baseUrl}/trigger`,
        API_REPHRASE_TEXT: `${baseUrl}/rephrase_text`,
        API_UPDATE_STAGE_SEQUENCE: `${baseUrl}/update_stage_index`,
        API_PAGINATED_SCHEDULE: `${baseUrl}/schedules`,
        API_DROPDOWN_SCHEDULE: `${baseUrl}/schedules/dropdown`,
        API_TEMPLATE_BY_ID: `${baseUrl}/get_template_by_id`,
        API_DEFAULT_SCHEDULE: `${baseUrl}/make_default_schedule`,
        API_CHECKOUT_SESSION: `${baseUrl}/create-checkout-session`,
        API_PLANS: `${baseUrl}/plans`,
        API_PEOPLE_GROUP: `${baseUrl}/group`,
        API_VALIDATE_RESET_TOKEN: `${baseUrl}/validate_reset_token`,
        API_ACCOUNT_STAGE: `${baseUrl}/account-stages`,
        API_UPDATE_ACCOUNT_STAGE_SEQUENCE: `${baseUrl}/update_account_stage_index`,
        API_DEFAULT_TIMEZONE: `${baseUrl}/fetch_default_schedule`,
        API_DEPENDENT_STEPS: `${baseUrl}/dependent_steps/list`
    }

    return apiList

}