import AWS from 'aws-sdk';

export const getS3Credentials = async () => {
    
  try {
    console.log("Fetching S3 credentials from Secrets Manager...");
    
    // Initialize the Secrets Manager client with temporary credentials
    const secretsClient = new AWS.SecretsManager({
      credentials: new AWS.Credentials({
        accessKeyId: process.env.REACT_APP_AWS_CLIENT_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_CLIENT_SECRET_ACCESS_KEY
      }),
      region: process.env.REACT_APP_AWS_REGION
    });

    // Get the secret value
    try {
      const response = await secretsClient.getSecretValue({
        SecretId: 's3-secrets'
      }).promise();
      
      console.log("Successfully retrieved secret from Secrets Manager");

      if ('SecretString' in response) {
        try {
          const secret = JSON.parse(response.SecretString);
          
          if (!secret.access_key || !secret.secret_key) {
            console.error("Error: Secret does not contain required keys");
            return null;
          }

          return {
            aws_access_key_id: secret.access_key,
            aws_secret_access_key: secret.secret_key,
            region_name: 'us-east-2'
          };
        } catch (e) {
          console.error("Error parsing secret JSON:", e);
          return null;
        }
      } else {
        console.error("Error: No SecretString in secret response");
        return null;
      }
    } catch (e) {
      console.error("Error accessing secret:", e);
      return null;
    }
  } catch (e) {
    console.error("Error setting up Secrets Manager client:", e);
    return null;
  }
};