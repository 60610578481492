import React, { useState, useEffect } from "react";
import { Col, Container } from 'react-bootstrap'
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactApexChart from 'react-apexcharts';
import { Calendar } from "primereact/calendar";
import { Skeleton } from "primereact/skeleton";
import Footer from "../Components/Footer/Footer";
import { Row } from "primereact/row";
import { fetchMethod } from "../Components/ApiHeader";
import ApiListFile from "../Components/ApiListFile";
import e from "cors";

const SalesExecutionReport = () => {
  // Static data for users
  const staticUsers = [
    { value: 'john@example.com', label: 'John Doe' },
    { value: 'jane@example.com', label: 'Jane Smith' },
    { value: 'bob@example.com', label: 'Bob Johnson' },
    { value: 'alice@example.com', label: 'Alice Brown' }
  ];

  // Static data for teams
  const staticTeams = [
    { value: '1', label: 'Sales Team A', team_members: [{ value: 'john@example.com' }, { value: 'jane@example.com' }] },
    { value: '2', label: 'Sales Team B', team_members: [{ value: 'bob@example.com' }, { value: 'alice@example.com' }] }
  ];

  const [selectedFilterUser, setSelectedFilterUser] = useState(null);
  const [selectedFilterTeams, setSelectedFilterTeams] = useState(null);
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRangeOption, setDateRangeOption] = useState("Monthly");
  // const [users] = useState(staticUsers);
  // const [teams] = useState(staticTeams);
  const [products, setProducts] = useState([]);

  const oneMonthBack = new Date();
  oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);


  const dateRangeOptions = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Yearly', value: 'Yearly' }
  ];

  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Current Period',
        data: [150, 200, 180, 250, 160, 220],
      },
      {
        name: 'Previous Period',
        data: [120, 180, 160, 220, 140, 190],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'string',
        categories: [
          'Companies assigned',
          'Prospects contacted',
          'Through sequences',
          'Emails delivered',
          'Calls - outbound',
          'Other tasks completed',
        ],
      },
      tooltip: {
        x: {
          format: '',
        },
      },
    }
  });

  // const handleTeamSelection = (selectedTeamId) => {
  //   setSelectedFilterTeams(selectedTeamId);
  //   const selectedTeam = teams.find(team => team.value === selectedTeamId);
  //   const teamMemberIds = selectedTeam?.team_members?.map(member => member.value) || [];
  //   setSelectedTeamMemberIds(teamMemberIds);
  // };

  // const updateDateRanges = (firstRangeStart, firstRangeEnd) => {
  //   let secondRangeStart = new Date(firstRangeStart);
  //   let secondRangeEnd = new Date(firstRangeEnd);

  //   switch (dateRangeOption) {
  //     case 'Weekly':
  //       secondRangeStart.setDate(secondRangeStart.getDate() - 7);
  //       secondRangeEnd.setDate(secondRangeEnd.getDate() - 7);
  //       break;
  //     case 'Monthly':
  //       secondRangeStart.setMonth(secondRangeStart.getMonth() - 1);
  //       secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 1);
  //       break;
  //     case 'Quarterly':
  //       secondRangeStart.setMonth(secondRangeStart.getMonth() - 3);
  //       secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 3);
  //       break;
  //     case 'Yearly':
  //       secondRangeStart.setFullYear(secondRangeStart.getFullYear() - 1);
  //       secondRangeEnd.setFullYear(secondRangeEnd.getFullYear() - 1);
  //       break;
  //     default:
  //       secondRangeStart.setMonth(secondRangeStart.getMonth() - 1);
  //       secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 1);
  //   }

  //   setFirstRange([firstRangeStart, firstRangeEnd]);
  //   setSecondRange([secondRangeStart, secondRangeEnd]);
  // };

  // useEffect(() => {
  //   const today = new Date();
  //   let startDate = new Date();

  //   switch (dateRangeOption) {
  //     case 'Weekly':
  //       startDate.setDate(today.getDate() - 7);
  //       break;
  //     case 'Monthly':
  //       startDate.setMonth(today.getMonth() - 1);
  //       break;
  //     case 'Quarterly':
  //       startDate.setMonth(today.getMonth() - 3);
  //       break;
  //     case 'Yearly':
  //       startDate.setFullYear(today.getFullYear() - 1);
  //       break;
  //     default:
  //       startDate.setMonth(today.getMonth() - 1);
  //   }

  //   updateDateRanges(startDate, today);
  // }, [dateRangeOption]);

  // useEffect(() => {
  //   setLoading(true);

  //   // Simulate API response with static data
  //   const staticData = [
  //     {
  //       id: '1000',
  //       code: 'Companies assigned',
  //       name: '150',
  //       category: '120',
  //     },
  //     {
  //       id: '1001',
  //       code: 'Prospects contacted',
  //       name: '200',
  //       category: '180',
  //     },
  //     {
  //       id: '1002',
  //       code: 'Through sequences',
  //       name: '180',
  //       category: '160',
  //     },
  //     {
  //       id: '1003',
  //       code: 'Emails delivered',
  //       name: '250',
  //       category: '220',
  //     },
  //     {
  //       id: '1004',
  //       code: 'Calls - outbound',
  //       name: '160',
  //       category: '140',
  //     },
  //     {
  //       id: '1005',
  //       code: 'Other tasks completed',
  //       name: '220',
  //       category: '190',
  //     },
  //   ];

  //   // Update products with static data
  //   setProducts(staticData);

  //   // Update chart data
  //   setChartData({
  //     ...chartData,
  //     series: [
  //       {
  //         name: firstRange[0] && firstRange[1]
  //           ? `${firstRange[0].toLocaleDateString()} - ${firstRange[1].toLocaleDateString()}`
  //           : 'Current Period',
  //         data: staticData.map(item => parseInt(item.name)),
  //       },
  //       {
  //         name: secondRange[0] && secondRange[1]
  //           ? `${secondRange[0].toLocaleDateString()} - ${secondRange[1].toLocaleDateString()}`
  //           : 'Previous Period',
  //         data: staticData.map(item => parseInt(item.category)),
  //       },
  //     ],
  //   });

  //   // Simulate API delay
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, [secondRange, firstRange, selectedFilterUser, selectedTeamMemberIds, selectedFilterTeams]);

  const CustomHeader = () => {
    let oldValue, newValue;
    if (products && products.length > 0) {
      oldValue = Number(products[1]?.name) || 0;
      newValue = Number(products[1]?.category) || 0;
    } else {
      oldValue = 0;
      newValue = 0;
    }
    let percentageChange = 0;

    if (oldValue !== 0) {
      percentageChange = ((newValue - oldValue) / oldValue) * 100;
    }

    const formattedPercentage = percentageChange.toFixed(2) + '%';

    return (
      <>
        <div className="sales-execuation-report-card-accrodian-header">
          <h4>Prospects contacted</h4>
          {loading ? (
            <Skeleton width="10rem" height="35px" className="m-3" />
          ) : (
            <div className="sales-execuation-report-card-accrodian-header-numbers">
              <h5 className={percentageChange >= 0 ? "positive" : "negative"}>{formattedPercentage}</h5>
              <h6>{`${oldValue} vs ${newValue}`}</h6>
            </div>
          )}
        </div>
      </>
    );
  };





  ///////////////////////////////////////////////////////////////////////
  const { API_GET_REPORT_DROPDOWN, API_GET_SALES_EXECUTION_REPORT } = ApiListFile()

  const calculateDateRanges = (rangeType) => {
    const today = new Date();
    let firstRangeEnd = new Date();
    let firstRangeStart = new Date();
    let secondRangeEnd = new Date();
    let secondRangeStart = new Date();

    switch (rangeType) {
      case 'Weekly':
        // First range: Current week
        firstRangeStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
        firstRangeEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        // Second range: Previous week
        secondRangeEnd = new Date(firstRangeStart);
        secondRangeEnd.setDate(secondRangeEnd.getDate() - 1);
        secondRangeStart = new Date(secondRangeEnd);
        secondRangeStart.setDate(secondRangeStart.getDate() - 6);
        break;

      case 'Monthly':
        // First range: Current month
        firstRangeStart = new Date(today.getFullYear(), today.getMonth(), 1);
        firstRangeEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        // Second range: Previous month
        secondRangeStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        secondRangeEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        break;

      case 'Quarterly':
        // First range: Current quarter
        const currentQuarter = Math.floor(today.getMonth() / 3);
        firstRangeStart = new Date(today.getFullYear(), currentQuarter * 3, 1);
        firstRangeEnd = new Date(today.getFullYear(), (currentQuarter + 1) * 3, 0);

        // Second range: Previous quarter
        secondRangeStart = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1);
        secondRangeEnd = new Date(today.getFullYear(), currentQuarter * 3, 0);
        break;

      case 'Yearly':
        // First range: Current year
        firstRangeStart = new Date(today.getFullYear(), 0, 1);
        firstRangeEnd = new Date(today.getFullYear(), 11, 31);

        // Second range: Previous year
        secondRangeStart = new Date(today.getFullYear() - 1, 0, 1);
        secondRangeEnd = new Date(today.getFullYear() - 1, 11, 31);
        break;

      default:
        // Default to Monthly
        firstRangeStart = new Date(today.getFullYear(), today.getMonth(), 1);
        firstRangeEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        secondRangeStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        secondRangeEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    }

    return {
      firstRange: [firstRangeStart, firstRangeEnd],
      secondRange: [secondRangeStart, secondRangeEnd]
    };
  };

  

  // Initialize default date ranges (Monthly)
const defaultRanges = calculateDateRanges('Monthly');
const [firstRange, setFirstRange] = useState(defaultRanges.firstRange);
const [secondRange, setSecondRange] = useState(defaultRanges.secondRange);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([])
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRange, setSelectedRange] = useState('Monthly');
  const [selectedCompany, setSelectedCompany] = useState(null);


  // Update ranges when range type changes
  useEffect(() => {
    const newRanges = calculateDateRanges(dateRangeOption);
    setFirstRange(newRanges.firstRange);
    setSecondRange(newRanges.secondRange);
  }, [dateRangeOption]);

  // Modified range type handler
  const handleRangeTypeChange = (e) => {
    setSelectedRange(e.value);
    setDateRangeOption(e.value);
  };


  const [dropDownData, setDropDownData] = useState({
    teams: null,
    users: null,
    companies: null,
    is_admin: false,
    is_superuser: false,
    is_team_lead: false
  });

  const handleCompanyChange = (e) => {
    const value = e.value
    let allTeams;
    let allUsers;
    if (value) {
      // Filter teams based on selected company
      allTeams = dropDownData.teams.filter((team) => team.company_id === value);
      // Filter users based on selected company
      allUsers = dropDownData.users.filter((user) => user.company_id === value);
    } else {
      allTeams = dropDownData.teams;
      allUsers = dropDownData.users;
    }

    const isCompanyTeam = allTeams.some((team) => team?.team_leadid === selectedTeam)
    if (!isCompanyTeam || !value) {
      setSelectedTeam(null);
      setSelectedUser(null);
    } else {
      allUsers = getTeamHierarchyUsers(selectedTeam, value, allUsers, new Set());
      const leader = dropDownData.users.find((user) => user?.user_id === selectedTeam);
      allUsers = [...allUsers, leader];
      allUsers.sort((a, b) => a.user_name.localeCompare(b.user_name));
    }
    console.log("Team users:", allUsers.length);
    console.log("Company teams:", allTeams.length);


    setTeams(allTeams);
    setUsers(allUsers);

    setSelectedCompany(value)
  }

  useEffect(() => {
    fetchDropDownData()

  }, [])


  const getTeamHierarchyUsers = (teamId, companyId, users, visited = new Set()) => {
    // base case for recursion
    if (teamId === 0 || teamId === null || visited.has(teamId)) return [];

    // Mark this teamId as visited
    visited.add(teamId);
    let result = [];
    const teamUsers = users.filter((user) => user.team_leadid === teamId && (!companyId ? true : user.company_id === companyId));
    teamUsers.map((user) => {
      const r = getTeamHierarchyUsers(user.user_id, companyId, users, visited);
      result.push(...r, user);
    });

    return result;

  }

  const fetchDropDownData = async () => {
    try {
      const data = await fetchMethod("GET", `${API_GET_REPORT_DROPDOWN}`, {})
      setDropDownData((prev) => ({
        ...prev,
        teams: data.teams,
        users: data.users,
        companies: data.companies,
        is_admin: data.is_admin,
        is_superuser: data.is_superuser,
        is_team_lead: data.is_team_lead
      }));

      if (data.is_superuser || data.is_admin || data.is_team_lead) {
        setTeams(data.teams);
        setUsers(data.users);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setDropDownLoader(false);
    }
  };

  console.log("Teams:", teams);
  console.log("Users:", users);

  console.log("Selected Team:", selectedTeam);
  console.log("Selected User:", selectedUser);
  console.log("Selected range", firstRange, "second===", secondRange);
  console.log("Selected range type", selectedRange);

  useEffect(() => {
    fetchExecutionReports()
  }, [selectedTeam, selectedUser, firstRange, secondRange, selectedRange])


  const fetchExecutionReports = async () => {
    try {
      const data = await fetchMethod("POST", `${API_GET_SALES_EXECUTION_REPORT}`, {
        "team_members": selectedTeam,
        "user_id": selectedUser,
        "company_id": "167791",
        "range_type": selectedRange,
        "first_range_strings": firstRange,
        "second_range_strings": secondRange
      })
      console.log("Data execution reports:", data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setDropDownLoader(false);
    }
  }
  




  return (
    <div className="content-page">
      <div className="content">
        <Container fluid className="">
          <Row>
            <Col>
              <div className="page-title-box">
                <h4 className="page-title">Sales execution report</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="page-title-caption-box">
                <h6 className="page-title-caption">Get a holistic view of activity across channels to uncover what contributed to your outcomes.</h6>
              </div>
            </Col>
          </Row>
          <div className="card">
            <Card className="p-0">
              <div className="sales-execuation-report-card">
                {/* <div className="sales-execuation-report-card-heading">
                <h4>Sales execution report</h4>
                <p className="m-0">
                  Get a holistic view of activity across channels to uncover what contributed to your outcomes.
                </p>
              </div> */}
                <div className="SalesExecutionReport-timeframe-timerange mb-3">
                  <div className="d-flex justify-content-center">
                    <Dropdown
                      value={selectedCompany}
                      onChange={handleCompanyChange}
                      options={dropDownData.companies}
                      optionLabel="company_name"
                      optionValue="company_id"
                      placeholder="Select a company"
                      showClear
                      filter
                      className="w-full md:w-14rem me-3"
                      panelClassName="dropdown-panel"
                    />
                    <Dropdown
                      value={selectedTeam}
                      onChange={(e) => setSelectedTeam(e.value)}
                      options={teams}
                      optionLabel="team_lead_name"
                      optionValue="team_leadid"
                      showClear
                      filter
                      className="me-2"
                      placeholder="Select a Team"
                    />
                    <Dropdown
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.value)}
                      options={users}
                      optionLabel="user_name"
                      optionValue="user_id"
                      placeholder="Select a User"
                      filter
                      className="w-full md:w-14rem me-2"
                      panelClassName="dropdown-panel"
                      showClear
                    />
                    <Dropdown
                      value={selectedRange}
                      options={dateRangeOptions}
                      onChange={handleRangeTypeChange}
                      optionLabel="label"
                      optionValue="value"
                      className="me-2"
                      placeholder="Select Range"
                    />
                    <Calendar
                      value={secondRange}
                      onChange={(e) => setSecondRange(e.value)}
                      selectionMode="range"
                      showIcon
                      className="mx-3"
                      dateFormat="dd/mm/yy"
                    />
                    <h6 className="mt-3">vs</h6>
                    <Calendar
                      value={firstRange}
                      onChange={(e) => setFirstRange(e.value)}
                      selectionMode="range"
                      showIcon
                      className="mx-3"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                </div>
                <div className="collpase-SalesExecutionReport-timeframe-timerange">
                  <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={<CustomHeader />}>
                      {loading ? (
                        <div className="row">
                          <div className="col-md-6"><Skeleton width="100%" height="200px" /></div>
                          <div className="col-md-6"><Skeleton width="100%" height="200px" /></div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="collpase-SalesExecutionReport-table-card table-responsive">
                              <DataTable value={products} tableStyle={{ minWidth: '100%' }}>
                                <Column field="code" header="Activity" style={{ width: "50%" }}></Column>
                                <Column
                                  field="name"
                                  header={firstRange[0] && firstRange[1] ?
                                    `${firstRange[0].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })} - ${firstRange[1].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`
                                    : ''}
                                  style={{ width: "25%" }}
                                ></Column>
                                <Column
                                  field="category"
                                  header={secondRange[0] && secondRange[1] ?
                                    `${secondRange[0].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })} - ${secondRange[1].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`
                                    : ''}
                                  style={{ width: "25%" }}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="collpase-SalesExecutionReport-table-card">
                              <div id="chart">
                                <ReactApexChart
                                  options={chartData.options}
                                  series={chartData.series}
                                  type="area"
                                  height={350}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </Card>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default SalesExecutionReport;