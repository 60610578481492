import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import "./Dashboard.css"
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom"
import { Tooltip } from "primereact/tooltip";
import Select from "react-select";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { Dropdown } from "primereact/dropdown";
import SelectSequenceChart from "../Common/Chart/SelectSequenceChart";
import ContactsChart from "../Common/Chart/ContactsChart";
import Footer from "../Components/Footer/Footer";
import { contextVar } from "../Components/ContextVar";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarToday } from "react-icons/md";
import Cookies from 'js-cookie';

const Dashboard = () => {

  let timezone_mapping = {
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "UTC": "UTC",
    "Europe/London": "Europe/London",
    "Paris": "Europe/Paris",
    "Athens": "Europe/Athens",
    "Australia Eastern Standard Time": "Australia/Sydney",
    "Australia Western Standard Time": "Australia/Perth",
    "Australia Central Standard Time": "Australia/Adelaide",
    "Middle East Time": "Asia/Tehran",
    "Near East Time": "Asia/Dubai",
    "Pakistan Lahore Time": "Asia/Karachi",
    "Indian Standard Time": "Asia/Kolkata",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
    "Japan Standard Time": "Asia/Tokyo",
    "Solomon Standard Time": "Pacific/Guadalcanal",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Midway Islands Time": "Pacific/Midway",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
    "Canada Newfoundland Time": "America/St_Johns",
    "Brazil Eastern Time": "America/Sao_Paulo"
  }


  //List of api used in this file
  const {
    API_USERS,
    API_GET_TASKS,
    API_GET_DASHBOARD_STATUS,
    API_PENDING_TASK_PAGINATED,
    API_COMPLETE_TASK,
    API_PAGINATED_SEQUENCE,
    API_PAGINATED_RECIPIENT,
    API_DASHBOARD_LEAD_COUNT,
    API_DASHBOARD_GET_YEARWISE_LEADS,
    API_EMAIL_SEQUENCE_STATS,
    API_POST_USER_TIMEZONES
  } = ApiListFile()

  const { setnetworkError, userGroup, userCredential } = useContext(contextVar)

  const planStatus = userCredential?.plan_status;


  const [timezone, setTimezone] = useState('UTC')
  // const [timezoneList, settimeZoneList] = useState('')
  // Usestates
  const [tasks, setTasks] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dashboardStats, setDashboardStats] = useState([]);
  const dt = useRef(null);
  const toast = useRef(null);
  const [lastWeekDashboard, setLastWeekDashboard] = useState([])
  const [currentTask, setCurrentTask] = useState({
    task: "",
    recipients: [],
    due_date: null,
    priority: "",
    note: "",
  });
  const [loading, setLoading] = useState(false);
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [loadingContactsDetails, setLoadingContactsDetails] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [fetchedTaskLoader, setFetchedTaskLoader] = useState(false);
  const [sequenceDropdownloading, setSequenceDropdownloading] = useState(false);
  const [validation, setValidation] = useState({ lead: "", task_type: "", priority: "" })
  const [dateRangeOption, setDateRangeOption] = useState("Weekly");
  const [contactsDetails, setContactsDetails] = useState();
  const [leadsCount, setLeadsCount] = useState();
  const [date, setDate] = useState([
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), // One week ago
    new Date()
  ]);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "timestamp",
    sortOrder: -1,
  });

  const onSort = (event) => {
    setlazyState({
      ...lazyState,
      sortOrder: event.sortOrder,
      sortField: event.sortField,
    });
  };

  // Retrieve the URL of the second page from session storage
  const backUrl = sessionStorage.getItem('backUrl');
  const backUrlName = sessionStorage.getItem('backUrlName');
  const [selectedSequence, setSelectedSequence] = useState(backUrl == null ? null : backUrl);
  const [selectedSequenceName, setSelectedSequenceName] = useState(backUrlName == null ? null : backUrlName);
  const [sequences, setSequences] = useState([]);
  const sequencesCache = useRef({});
  const loadLazyTimeout = useRef(null);
  const [sequenceValues, setSequenceValues] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [sequencesLoading, setSequencesLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);

  const [page, setPage] = useState(1);               // Track current page for pagination
  const [visibleRecipients, setVisibleRecipients] = useState([]);
  const [hasMoreRecords, setHasMoreRecords] = useState(true); // Track if there are more records to load
  const PAGE_SIZE = 10;  // Number of records to fetch per page

  const dateRangeOptions = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Yearly', value: 'Yearly' }
  ];

  const [selectContact, setSelectContact] = useState({ name: 'Last 30 days', code: 'last_30_days' });
  const contactsTime = [
    { name: 'Last 30 days', code: 'last_30_days' },
    { name: 'Last 6 months', code: 'last_6_months' },
    { name: 'Last 1 year', code: 'last_1_year' }
  ];
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const numberOfDaysToMonday =
    currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1; // Sunday is 0 in JS, so we adjust accordingly
  const start_date = new Date(currentDate);
  start_date.setDate(start_date.getDate() - numberOfDaysToMonday);
  start_date.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  const end_date = new Date(start_date);
  end_date.setDate(end_date.getDate() + 6);
  end_date.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="position-relative">
        <input
          className="form-control"
          type="text"
          value={value}
          onClick={onClick}
          readOnly
          ref={ref}
        />
        <MdCalendarToday
          className="position-absolute top-50 end-0 translate-middle-y me-2"
          style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          onClick={onClick}
        />
      </div>
    )
  });

  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data) => {
        if (data?.item != null) {
          const apiTimezone = data?.item?.timezone;

          const validTimezone = timezone_mapping[apiTimezone] || 'UTC';
          setTimezone(validTimezone);

          // Set initial date in the user's timezone
          const initialDate = new Date(moment.tz(new Date(), validTimezone).format('YYYY-MM-DDTHH:mm:ss'));
          setCurrentTask(prev => ({ ...prev, due_date: initialDate }));
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
        }
      });
  }, []);

  const taskOptions = [
    { value: "phone call", label: "Phone Call" },
    { value: "manual email", label: "Manual Email" },
    { value: "action item", label: "Action Item" },
  ];

  const priorityOptions = [
    { value: "high priority", label: "High Priority" },
    { value: "medium priority", label: "Medium Priority" },
    { value: "low priority", label: "Low Priority" },
  ];
  // Function to calculate the date range based on the selected option
  const calculateDateRange = (rangeOption) => {
    const endDate = new Date();
    let startDate = new Date();

    switch (rangeOption) {
      case 'Weekly':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case 'Monthly':
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case 'Quarterly':
        startDate.setMonth(endDate.getMonth() - 3);
        break;
      case 'Yearly':
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        // Default to monthly if rangeOption is not recognized
        // startDate.setMonth(endDate.getMonth() - 1);
        startDate.setDate(endDate.getDate() - 7);
    }

    return [startDate, endDate];

  };

  const navigate = useNavigate();
  // useEffect to update the date based on the selected dateRangeOption

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + Cookies.get('accessToken')); // Replace with the actual access token if needed

    const requestData = {
      menu_id: 5
    };

    const requestOptions = {
      method: 'POST', // Using POST method, as in the curl request
      headers: myHeaders,
      body: JSON.stringify(requestData), // Send the JSON payload
    };

    const globalapi = process.env.REACT_APP_GLOBAL_API; // Assuming your global API base URL is set here
    fetch(`${globalapi}/api/v1/menu/track-click/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result); // Handle the result here (success response)
      })
      .catch((error) => console.error('Error:', error)); // Handle any errors here
  }, []);






  useEffect(() => {
    const newDateRange = calculateDateRange(dateRangeOption);
    setDate(prevDate => {
      return newDateRange;
    });
  }, [dateRangeOption]);

  useEffect(() => {
    if (userGroup?.dashboard_stats?.dashboard_leads_count?.R) {
      fetchLeadData();
    }

  }, [selectContact]);

  useEffect(() => {
    fetchYearWiseLeads();
  }, []);

  useEffect(() => {
    fetchSequences(lazyState);
    getSequencewiseEmailDetails();
  }, [selectedSequence, globalFilter]);

  const handleDateRange = (val) => {
    setDateRangeOption(val)
    calculateDateRange()
  }

  const getSequencewiseEmailDetails = async () => {
    if (selectedSequence != null) {
      setLoadingEmail(true);

      try {
        const data = await fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${selectedSequence}`)
        if (data && data.status !== "error") {
          setSequenceValues(data);
        }
        setLoadingEmail(false);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setLoadingEmail(false);
      }
    }
  }

  const onLazyLoad = (event) => {
    setSequenceDropdownloading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const page = Math.floor(first / 10);
      const alreadyFetched = sequencesCache.current[page];

      if (!alreadyFetched) {
        fetchSequences({ page: page, page_size: 10 }); // Adjust page_size as needed
      } else {
        setSequenceDropdownloading(false);
      }
      setSequenceDropdownloading(false);
    },); // Adjust delay as needed
  };
  const fetchSequences = async ({ page, page_size }) => {
    sessionStorage.removeItem('backUrl');
    sessionStorage.removeItem('backUrlName');
    setlazyState({ ...lazyState, page_size: lazyState.page_size + 10, search_query: globalFilter, });
    setSequenceDropdownloading(false);
    try {
      const data = await fetchMethod("POST", `${API_PAGINATED_SEQUENCE}`, lazyState)
      if (data && data.items) {
        const filteredSequences = data.items.filter(item => item.sequence_name && item._id).map(item => ({
          label: item.sequence_name,
          value: item._id,
        }));

        // If previously selected sequence is not in the new list, add it
        const exists = filteredSequences.some(sequence => sequence.value === selectedSequence);
        if (!exists && selectedSequence && selectedSequenceName) {
          filteredSequences.push({
            label: selectedSequenceName,
            value: selectedSequence,
          });
        }

        setSequences(filteredSequences);
        setSequencesLoading(false);
        if (filteredSequences.length > 0 && !selectedSequence) {
          const firstSequence = filteredSequences[0];
          setSelectedSequence(firstSequence.value);
          setSelectedSequenceName(firstSequence.label);
        }
      }
    } catch (error) {
      if (error instanceof TypeError) {
        setnetworkError(true); // Set networkError state to true
        return;
      }
    } finally {
      setSequenceDropdownloading(false);
    }
  };

  useEffect(() => {
    onLazyLoad({ first: 0, last: 10 }); // Initial load
  }, []);

  const fetchYearWiseLeads = async () => {
    setLoadingContactsDetails(true);
    try {
      const data = await fetchMethod("GET", `${API_DASHBOARD_GET_YEARWISE_LEADS}`, {})
      await setContactsDetails(data);
      if (contactsDetails) {
        setLoadingContactsDetails(false);
      }
      setLoadingContactsDetails(false);
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true)
        setLoadingContactsDetails(false);
        return;
      }
    }
  };

  const fetchLeadData = async () => {
    setLeadsLoading(true);
    const body = {
      date_range: selectContact.code,
    };

    try {
      const data = await fetchMethod("POST", `${API_DASHBOARD_LEAD_COUNT}`, body)
      setLeadsCount(data);
      setLeadsLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true)
        setLeadsLoading(false);
        return;
      }
    }
  };


  const fetchTasks = async () => {
    setLoading(true);
    try {
      const data = await fetchMethod("POST", `${API_PENDING_TASK_PAGINATED}`, {
        page: lazyState.page,
        page_size: lazyState.rows,
        search_query: globalFilter,
        sort_order: lazyState.sortOrder,
        sort_field: lazyState.sortField,
      })
      const fetchedTasks = data?.items.map((task) => ({
        ...task,
        task: taskOptions?.find((option) => option?.value === task?.task)?.label,
        priority: priorityOptions?.find((option) => option?.value === task?.priority)?.label,
        due_date: moment(task?.due_date, "YYYY-MM-DD").format("D/M/YYYY"),
      }));
      setTasks(fetchedTasks);
      setTotalRecords(data?.total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error signing up", error);
      if (error instanceof TypeError) {
        setnetworkError(true)
        setLoading(false);
        return;
      }
    }
  }


  useEffect(() => {
    document.title = "Analytics";
    fetchDashboardStatus()
    fetchWeeklyDashboardStatus();
  }, [date[0], date[1]]);

  useEffect(() => {
    fetchTasks();
  }, [lazyState]);

  //function to fetch dashboards status
  const fetchDashboardStatus = async () => {
    setLoading(true);
    const requestBody = {
      'start_date': date[0],
      'end_date': date[1]
    };
    try {

      const data = await fetchMethod("POST", `${API_GET_DASHBOARD_STATUS}`, requestBody)
      setDashboardStats(data);
      if (dashboardStats) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeeklyDashboardStatus = () => {
    const adjustedStartDate = moment(date[0]).subtract(1, 'week');
    const adjustedEndDate = moment(date[1]).subtract(1, 'week');

    const requestBody = {
      'start_date': adjustedStartDate.toISOString(),
      'end_date': adjustedEndDate.toISOString()
    };
    fetchMethod("POST", `${API_GET_DASHBOARD_STATUS}`, requestBody)
      .then((data) => {
        setLastWeekDashboard(data);
        if (lastWeekDashboard) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          console.log("Network error occurred");
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    let newValidation = { lead: "", task_type: "", priority: "" };

    // Validate the name field
    if (!currentTask.recipients || currentTask.recipients.length == 0) {
      isValid = false;
      newValidation.lead = "Lead is required";
    }

    // Validate the subject field
    if (!currentTask.task) {
      isValid = false;
      newValidation.task_type = "Task Type is required";
    }

    if (!currentTask.priority) {
      isValid = false;
      newValidation.priority = "Priority is required";
    }

    // Update the validation state
    setValidation(newValidation);

    if (isValid) {
      setLoading(true)
      const payload = {
        task: currentTask.task.value,
        recipients: currentTask.recipients,
        due_date: moment(currentTask.due_date).format('YYYY-MM-DDTHH:mm'),
        priority: currentTask.priority.value,
        note: currentTask.note
      }
      console.log("dashboard payload", currentTask)
      // const url = process.env.REACT_APP_API_HOST + "/tasks" + (currentTask._id ? `/${currentTask._id}` : "");
      const url = `${API_GET_TASKS}` + `${(currentTask._id ? `/${currentTask._id}` : "")}`;
      const method = currentTask._id ? "PUT" : "POST";
      fetchMethod(method, `${url}`, payload)
        .then((data) => {
          setLoading(false)
          if (currentTask._id) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Task Updated Successfully",
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Task Added Successfully",
            });
          }
          fetchTasks()
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          if (err instanceof TypeError) {
            console.log("Network error occurred");
            setnetworkError(true)
            setLoading(false);
            return;
          }
        });
    }
  };

  const handleComplete = (id) => {
    setLoading(true)
    const url = `${API_COMPLETE_TASK}/${id}`;
    fetchMethod("PUT", `${url}`, {})
      .then((data) => {
        setLoading(false)
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Task Completed Successfully",
        });
        setTasks(tasks.filter((task) => task._id !== id));
        fetchTasks()
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          console.log("Network error occurred");
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  };

  const handleDelete = (id) => {
    fetchMethod("DELETE", `${API_GET_TASKS}/${id}`, {})
      .then(() => {
        setTasks(tasks.filter((task) => task._id !== id));
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Task Deleted Successfully",
        });
      })
    fetchTasks()
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          console.log("Network error occurred");
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  };

  const handleClose = () => {
    setCurrentTask({
      task: "",
      recipients: [],
      due_date: null,
      priority: "",
      note: "",
    })
    setShowModal(false);
    setValidation({ lead: "", task_type: "", priority: "" });
  };
  const handleShow = (task) => {


    const recipientsList =
      task?.recipients?.label && task?.recipients?.value
        ? {
          label: task.recipients.label,
          value: task.recipients.value.trim(),
        }
        : undefined;

    const priorityOption = priorityOptions.find(
      (priority) => priority.label === task?.priority
    );
    const taskTypeOption = taskOptions.find(
      (task_option) => task_option.label === task?.task
    );

    setCurrentTask({
      ...task,
      recipients: recipientsList,
      due_date: new Date(moment.tz(new Date(), timezone).format('YYYY-MM-DDTHH:mm:ss')),

      priority: priorityOption,
      task: taskTypeOption,
    });

    setShowModal(true);
  };

  const handleEdit = (task) => {
    console.log("edit task", task);

    const recipientsList =
      task?.recipients?.label && task?.recipients?.value
        ? {
          label: task.recipients.label,
          value: task.recipients.value.trim(),
        }
        : undefined;

    const priorityOption = priorityOptions.find(
      (priority) => priority.label === task?.priority
    );
    const taskTypeOption = taskOptions.find(
      (task_option) => task_option.label === task?.task
    );

    console.log("task duedate", task?.due_date);

    const dueDateString = task?.due_date; // Assuming format is "DD/MM/YYYY"
    const dueDate = moment.tz(dueDateString, "DD/MM/YYYY", timezone).toDate(); // Convert to Date object in specified timezone

    const formattedDueDate = moment(dueDate).format("DD-MM-YYYY"); // Format for display
    console.log("formattedDueDate========", formattedDueDate);

    setCurrentTask({
      ...task,
      recipients: recipientsList,
      due_date: dueDate, // Store as Date object for date picker
      priority: priorityOption,
      task: taskTypeOption,
    });

    setShowModal(true);
  };



  const taskTemplate = (rowData) => {
    return rowData.task;
  };

  const dueTemplate = (rowData) => {
    return rowData.due_date
  };

  const priorityTemplate = (rowData) => {
    // Determine the severity based on the priority value
    let severity;
    if (rowData.priority === "High Priority") {
      severity = "danger";
    } else if (rowData.priority === "Medium Priority") {
      severity = "warning";
    } else {
      severity = "info";
    }

    // Return the badge with a tooltip
    return (
      <>
        <Tooltip target=".priority-badge" />
        <Badge
          value={rowData.priority}
          className="ms-auto priority-badge"
          severity={severity}
          data-pr-tooltip={rowData.priority}
        />
      </>
    );
  };

  const leadTemplate = (rowData) => {
    return rowData?.recipients?.label;
  };

  const noteTemplate = (rowData, column) => {
    const note = rowData['note'];
    const maxLength = 10;
    let truncatedBody;
    if (note != undefined) {
      const cleanedBody = note.replace(/(<([^>]+)>)/gi, "");
      truncatedBody =
        cleanedBody.length > maxLength
          ? `${cleanedBody.substring(0, maxLength)}...`
          : cleanedBody;
    } else {
      truncatedBody = <span></span>
    }

    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <span data-pr-tooltip={note} className="tooltip-note-text">{truncatedBody}</span>
      </>
    )
  }

  const accept = (id) => {
    handleDelete(id)
  }

  const reject = (id) => {

  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-edit-text" />
        <Tooltip target=".tooltip-delete-text" />
        <Tooltip target=".tooltip-complete-text" />
        {userGroup?.task?.complete_task?.A &&
          <Button
            className="tooltip-complete-text"
            icon="pi pi-check"
            rounded
            text
            aria-label="Mark as Complete"
            data-pr-tooltip="Mark as Complete"
            loading={loading}
            onClick={() => handleComplete(rowData._id)}
          />}
        {userGroup?.task?.delete_task?.D &&
          <Button
            className="tooltip-delete-text"
            icon="uil uil-trash"
            rounded
            text
            aria-label="Delete"
            data-pr-tooltip="Delete"
            severity="danger"
            onClick={() => confirmdelete(rowData._id)}
          />}
        {userGroup?.task?.update_task?.C &&
          <Button
            className="tooltip-edit-text"
            icon="uil uil-edit-alt"
            rounded
            text
            aria-label="Edit"
            data-pr-tooltip="Edit"
            onClick={() => handleEdit(rowData)}
          />}
      </div>
    );
  };

  const formatDate = (date) => {
    // Check if date is valid, if not return an empty string or null
    if (!date || isNaN(new Date(date).getTime())) {
      return '';
    }
    return new Date(date).toISOString().split('T')[0]; // Safely format the date
  };

  useEffect(() => {
    const initialOption = sequences.find(option => option.value === selectedSequence);
    if (initialOption) {
      setSelectedSequenceName(initialOption.label);
    }
  }, [selectedSequence]);

  const handleViewReports = (sequenceId, selectedSequenceName) => {
    navigate(`/sequenceReport/${encodeURIComponent(sequenceId)}/${encodeURIComponent(selectedSequenceName)}`);
  }

  const getTodayInSelectedTimezone = () => {
    const mappedTimezone = timezone_mapping[timezone] || timezone;
    const currentDateInTimezone = moment.tz(mappedTimezone).startOf('day');
    return currentDateInTimezone.toDate();
  };

  const minDateForDatePicker = getTodayInSelectedTimezone();

  const isHighlightedDay = (date) => {
    // Check if the current day should be highlighted based on the formatted date and timezone
    const formattedDate = moment.tz(currentTask.due_date, timezone).format('DD-MM-YYYY');
    const dayToCheck = moment(date).format('DD-MM-YYYY');
    return formattedDate === dayToCheck;
  };

  const isHighlightedTime = (time) => {
    // Check if the current time should be highlighted based on the formatted date and timezone
    const formattedTime = moment.tz(currentTask.due_date, timezone).format('hh:mm A');
    const timeToCheck = moment(time).format('hh:mm A');
    return formattedTime === timeToCheck;
  };

  const handleStartDateChange = (date) => {

    setCurrentTask((prev) => ({
      ...prev,
      due_date: date,
    }));
  };

  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1
    });
    // if (activeTabIndex === 0) {
    //   setlazyState(prev => ({ ...prev, ...newLazyState }));
    // } else {
    //   setlazyState(prev => ({ ...prev, ...newLazyState }));
    // }
  };

  const fetchRecipientList = async (params) => {
    if (loading || !hasMoreRecords) return;  // Prevent fetching if already loading or no more records
    setLoading(true);

    try {
      const data = await fetchMethod("POST", `${API_PAGINATED_RECIPIENT}`, params);

      if (data && data.items) {
        const recipientOptions = data.items.map((recipient) => ({
          label: `${recipient.first_name.trim()} ${recipient.last_name.trim() || ''}`,
          value: recipient._id,
        }));

        // Append new recipients while keeping old ones
        setVisibleRecipients((prevRecipients) => [
          ...prevRecipients,
          ...recipientOptions,
        ]);


        // Check if all records have been fetched
        if (data.total_count <= (params.page * params.page_size)) {
          setHasMoreRecords(false);  // No more records to load
        }
      }
    } catch (error) {
      console.error("Error fetching recipients:", error);
    } finally {
      setLoading(false);  // End loading state
    }
  };
  // Fetch the first page when the component is mounted (initial load)
  useEffect(() => {
    fetchRecipientList({ page: 1, page_size: PAGE_SIZE, sortField: "timestamp", sortOrder: -1 });
  }, []);

  // Fetch new page when page number changes
  useEffect(() => {
    if (page > 1) {  // Ensure we don't refetch the first page
      fetchRecipientList({
        page,
        page_size: PAGE_SIZE,
        sortField: "timestamp",
        sortOrder: -1,
      });
    }
  }, [page]);

  // Function to handle scrolling down (fetch the next set of records)
  const handleMenuScrollToBottom = () => {
    if (!loading && hasMoreRecords) {
      setPage((prevPage) => prevPage + 1);  // Increment to next page
    }
  };

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid className="dashboard">
            <ConfirmDialog />
            <Toast ref={toast} />
            <Row>
              <Col>
                <div className="page-title-box">
                  <h4 className="page-title">Analytics</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-title-caption-box">
                  <h6 className="page-title-caption">Gain insights through real-time data visualizations and metrics that track your email campaign's performance.</h6>
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={5}>
                <Panel
                  header={
                    <div className="panel-header d-flex align-items-center">
                      <div className="panel-header-left ">
                        <h4 className="m-0">{loading ? <Skeleton width="200px" height="20px" /> : "Your status "}</h4>
                      </div>
                      {/* <div className="panel-header-right d-flex align-items-center">
                        {loading ? (
                          <Skeleton width="200px" height="20px" />
                        ) : (
                          <>
                            <span className="deliver_time"> This week </span>
                            <span>
                              {moment(start_date).format("MMM DD")} -{" "}
                              {moment(end_date).format("MMM DD")}{" "}
                            </span>
                            <Button size="small" icon="uil uil-angle-left" className="mx-2"/>
                                <Button size="small" icon="uil uil-angle-right"/> 
                          </>
                        )}
                      </div> */}

                      {loading ? <Skeleton width="200px" height="20px" /> :
                        <>
                          <Dropdown
                            value={dateRangeOption}
                            options={dateRangeOptions}
                            // onChange={(e) => setDateRangeOption(e.value)}
                            onChange={(e) => handleDateRange(e.value)}
                            className="me-3"
                            placeholder="Select Range"
                          />
                        </>
                      }
                    </div>
                  }
                >
                  {loading ? (
                    // Render Skeleton loaders for statistics when loading
                    <Skeleton height="100px" count={3} />
                  ) : (
                    <div className="dashboard-email-stats">
                      <div className="d-flex align-items-center mb-3">
                        <i className="pi pi-envelope"></i>
                        <h4>{dashboardStats.sent} emails delivered</h4>
                        {/* <Badge value="1 last week" className="ms-auto" severity="warning"></Badge> */}
                      </div>
                      {/* {dateRangeOption == 'Weekly'} */}

                      <Card title='' className="mb-3">
                        {dateRangeOption == 'Weekly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>WEEK-TO-DATE</h4>}
                        {dateRangeOption == 'Monthly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>MONTH-TO-DATE</h4>}
                        {dateRangeOption == 'Quarterly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>QUATER-TO-DATE</h4>}
                        {dateRangeOption == 'Yearly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>YEAR-TO-DATE</h4>}
                        {(!dashboardStats?.opened && dashboardStats.sent !== 0 && dashboardStats.scheduled !== 0 && dashboardStats.opt_out !== 0 && dashboardStats.bounced !== 0) || loading ? (
                          <Skeleton height="40px" count={3} />
                        ) : (
                          <div className="statistic_left">
                            <ul>
                              <li
                                className={`list-item ${planStatus === 'Free' || !planStatus ? 'disabled' : ''}`}
                                data-pr-tooltip={planStatus === 'Free' || !planStatus ? "You need to upgrade your plan to unlock more statistics" : ""}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"
                                style={{
                                  opacity: planStatus === 'Free' || !planStatus ? 0.5 : 1,
                                  cursor: planStatus === 'Free' || !planStatus ? 'not-allowed' : 'default'
                                }}
                              >
                                {planStatus === 'Free' || !planStatus ? (
                                  <a className="pi pi-lock"></a>
                                ) : (
                                  dashboardStats?.opened
                                )}
                                <span>Opened</span>
                                <Tooltip target=".disabled" />
                              </li>
                              <li>
                                {dashboardStats && typeof (dashboardStats?.scheduled) === "number" && typeof (dashboardStats?.sent) === "number" &&
                                  Math.max(dashboardStats?.scheduled - dashboardStats?.sent, 0)
                                }{" "}
                                <span>Scheduled</span>
                              </li>
                              <li>
                                {dashboardStats?.opt_out} <span>Unsubscribed</span>
                              </li>
                              <li>
                                {dashboardStats?.bounced} <span>Bounced</span>
                              </li>
                              <li>
                                {dashboardStats?.sent} <span>Sent</span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </Card>
                      <Card title="">
                        {dateRangeOption == 'Weekly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>LAST WEEK</h4>}
                        {dateRangeOption == 'Monthly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>LAST MONTH</h4>}
                        {dateRangeOption == 'Quarterly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>LAST QUATER</h4>}
                        {dateRangeOption == 'Yearly' && <h4 className="mb-2" style={{ fontSize: '13px' }}>LAST YEAR</h4>}
                        {(!lastWeekDashboard?.opened && lastWeekDashboard.sent !== 0 && lastWeekDashboard.scheduled !== 0 && lastWeekDashboard.opt_out !== 0 && lastWeekDashboard.bounced !== 0) || loading ? (
                          <Skeleton height="40px" count={3} />
                        ) : (
                          <div className="statistic_left">
                            <ul>
                              <li
                                className={`list-item ${planStatus === 'Free' || !planStatus ? 'disabled' : ''}`}
                                data-pr-tooltip={planStatus === 'Free' || !planStatus ? "You need to upgrade your plan to unlock more statistics" : ""}
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"
                                style={{
                                  opacity: planStatus === 'Free' || !planStatus ? 0.5 : 1,
                                  cursor: planStatus === 'Free' || !planStatus ? 'not-allowed' : 'default'
                                }}
                              >
                                {planStatus === 'Free' || !planStatus ? (
                                  <a className="pi pi-lock"></a>
                                ) : (
                                  lastWeekDashboard?.opened
                                )}
                                <span>Opened</span>
                                <Tooltip target=".disabled" />
                              </li>

                              <li>
                                {lastWeekDashboard && typeof (lastWeekDashboard?.scheduled) === "number" && typeof (lastWeekDashboard?.sent) === "number" &&
                                  Math.max(lastWeekDashboard?.scheduled - lastWeekDashboard?.sent, 0)
                                }{" "}
                                <span>Scheduled</span>
                              </li>
                              <li>{lastWeekDashboard?.opt_out} <span>Unsubscribed</span></li>
                              <li>{lastWeekDashboard?.bounced} <span>Bounced</span></li>
                              <li>{lastWeekDashboard?.sent} <span>sent</span></li>
                              {/* Add more items as needed */}
                            </ul>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </Panel>
              </Col>
              <Col md={7}>
                <Panel header={
                  <div className="dasboard-view-reports">
                    {
                      sequencesLoading ?
                        <Skeleton width="90px" height="40px"></Skeleton> : // Display a skeleton or loader while data is loading
                        sequences && sequences.length > 0 ? (
                          <Dropdown
                            value={selectedSequence}
                            options={sequences}
                            onChange={(e) => {
                              setSelectedSequence(e.value);
                              const selectedOption = sequences.find(option => option.value === e.value);
                              setSelectedSequenceName(selectedOption ? selectedOption.label : "");
                            }}
                            optionLabel="label"
                            className="w-full md:w-14rem dropdown-reports"
                            virtualScrollerOptions={{
                              lazy: true,
                              onLazyLoad: onLazyLoad,
                              itemSize: 38,
                              delay: 250
                            }}
                          />
                        ) : (
                          <h4 className="mb-2" style={{ fontSize: '13px' }}>No sequences found</h4> // Display only if sequences are indeed empty
                        )
                    }
                    {sequences[0]?.label != null && userGroup?.sequence?.get_sequencewise_email_details?.R &&
                      <Button
                        size="small"
                        label="View Reports"
                        icon="uil uil-plus"
                        onClick={() => handleViewReports(selectedSequence, selectedSequenceName)}
                      />
                    }
                  </div>
                }>
                  {loadingEmail ? <Skeleton height="200px"></Skeleton> :
                    <div>
                      <SelectSequenceChart sequenceValues={sequenceValues} />
                    </div>
                  }
                </Panel>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md="12">
                <Panel header={<div className="d-flex justify-content-between align-items-center">
                  <div>
                    Leads - <Dropdown value={selectContact} onChange={(e) => setSelectContact(e.value)} options={contactsTime} optionLabel="name" className="w-full md:w-14rem" />
                  </div>
                </div>}>
                  <Row>
                    <Col md="8">
                      {loadingContactsDetails ? <Skeleton height="250px"></Skeleton> :
                        <div className="card p-3 h-100">
                          <ContactsChart contactsDetails={contactsDetails} />
                        </div>
                      }
                    </Col>

                    <Col md="4">
                      <div className="card p-5 h-100 dashboard-contacts">
                        <Row className="mt-5">
                          <Col md="6" className="mb-2">
                            <div className="dashboard-contacts-card">
                              {leadsLoading ? <Skeleton></Skeleton> : <h4>{leadsCount?.total_leads || 0}</h4>}
                              <p>Total Leads</p>
                            </div>
                          </Col>
                          <Col md="6" className="mb-2">
                            <div className="dashboard-contacts-card">
                              {leadsLoading ? <Skeleton></Skeleton> : <h4>{leadsCount?.new_leads || 0}</h4>}
                              <p>New Leads</p>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="dashboard-contacts-card">
                              {leadsLoading ? <Skeleton></Skeleton> : <h4>{leadsCount?.unsubscribe || 0}</h4>}
                              <p>Unsubscribe</p>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="dashboard-contacts-card">
                              {leadsLoading ? <Skeleton></Skeleton> : <h4>{leadsCount?.bounced || 0}</h4>}
                              <p>Bounce</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                </Panel>
              </Col>
            </Row>

            {userGroup?.task?.get_tasks?.R &&
              <Row className="mb-4">
                <Col md={12}>
                  <Panel className="to-do-list"
                    header={
                      <div className="panel-header d-flex align-items-center">
                        <div className="panel-header-left ">
                          <h4 className="m-0">{fetchedTaskLoader ? <Skeleton width="200px" height="20px" /> : "To-do list"}</h4>
                        </div>
                        <div className="panel-header-right">
                          {fetchedTaskLoader ? (
                            <Skeleton width="200px" height="20px" />
                          ) : userGroup?.task?.add_task?.A && (
                            <Button
                              // size="small"
                              label="Add Task"
                              icon="uil uil-plus"
                              rounded
                              onClick={() =>
                                handleShow({
                                  task: "",
                                  due_date: new Date(),
                                  priority: "",
                                  recipients: [],
                                })
                              }
                            />
                          )}

                        </div>
                      </div>
                    }
                  >
                    {fetchedTaskLoader ? (
                      <DataTable
                        value={[...Array(5)]}
                      >
                        <Column
                          field="task"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="due_date"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="priority"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="lead"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="note"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="Action"
                          header={<Skeleton width="16%"></Skeleton>}
                          body={() => <Skeleton></Skeleton>}
                        />
                      </DataTable>
                    ) : (
                      <DataTable ref={dt} value={tasks}
                        lazy
                        // selection={selectedRows}
                        // onSelectionChange={handleSelectionChange}
                        // onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                        selectionMode="checkbox"
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        first={lazyState.first}
                        filters={lazyState.filters}
                        onPage={onPage}
                        globalFilter={globalFilter}
                        // header={header}
                        totalRecords={totalRecords}
                        removableSort paginator rows={lazyState.rows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"  >
                        <Column
                          field="task"
                          sortable
                          header="Task"
                          body={taskTemplate}
                        ></Column>
                        <Column
                          field="due_date"
                          sortable
                          header="Due Date"
                          body={dueTemplate}
                        ></Column>
                        <Column
                          field="priority"
                          sortable
                          header="Priority"
                          body={priorityTemplate}
                        ></Column>
                        <Column
                          field="recipients"
                          sortable
                          header="Lead"
                          body={leadTemplate}
                        ></Column>
                        <Column
                          field="note"
                          sortable
                          header="Note"
                          body={noteTemplate}
                        ></Column>
                        <Column header="Action" body={actionTemplate}></Column>
                      </DataTable>
                    )}
                  </Panel>
                </Col>
              </Row>}

            <Row>
              <Col xl={8}>{/*<Overview/>*/}</Col>
              <Col xl={4}>{/*<NewUsers/>*/}</Col>
            </Row>

            <Dialog header={currentTask._id ? 'Update Task' : 'Add Task'}
              visible={showModal}
              onHide={() => { setShowModal(false); setValidation({ lead: "", task_type: "", priority: "" }) }}
              style={{ width: "30vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              draggable={false}
              resizable={false}

            >
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Lead <span className="text-danger">*</span></Form.Label>
                  {/* <Select
                    options={recipients}
                    value={currentTask.recipients}
                    onChange={(value) =>
                      setCurrentTask({ ...currentTask, recipients: value })
                    }
                    isMulti
                    isSearchable
                    isDisabled={currentTask._id ? true : false}
                  /> */}
                  <Select
                    options={visibleRecipients}  // Show the accumulated recipients as they are fetched
                    value={currentTask.recipients}
                    onChange={(value) => {
                      setCurrentTask({ ...currentTask, recipients: value });
                    }}
                    isMulti
                    isSearchable
                    isDisabled={currentTask._id ? true : false}
                    onMenuScrollToBottom={handleMenuScrollToBottom}
                    isLoading={loading}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        paddingRight: '10px',
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: '200px',
                        overflowY: 'auto',
                      }),
                    }}
                    menuPosition="fixed"
                  />
                  <div className="text-danger">{validation.lead}</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Task Type <span className="text-danger">*</span></Form.Label>
                  <Select
                    options={taskOptions}
                    value={currentTask.task}
                    onChange={(value) =>
                      setCurrentTask({ ...currentTask, task: value })
                    }
                    isSearchable={false}
                  />
                  <div className="text-danger">{validation.task_type}</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Due Date <span className="text-danger">*</span></Form.Label>

                  {timezone && <DatePicker
                    selected={currentTask.due_date ? currentTask.due_date : null}
                    onChange={handleStartDateChange}
                    dateFormat="dd-MM-yyyy "
                    minDate={minDateForDatePicker}
                    customInput={<CustomInput />}
                    value={currentTask.due_date ? currentTask.due_date : 'Not set'}
                    dayClassName={(date) => (isHighlightedDay(date) ? 'highlighted-day' : undefined)}
                    timeClassName={(time) => (isHighlightedTime(time) ? 'highlighted-time' : undefined)}
                  />}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Priority <span className="text-danger">*</span></Form.Label>
                  <Select
                    options={priorityOptions}
                    value={currentTask.priority}
                    onChange={(value) =>
                      setCurrentTask({ ...currentTask, priority: value })
                    }
                    isSearchable={false}
                  />
                  <div className="text-danger">{validation.priority}</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={currentTask.note}
                    onChange={(event) =>
                      setCurrentTask({
                        ...currentTask,
                        note: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <div className='d-flex justify-content-end mt-2'>
                  <Button variant="secondary" type="button" onClick={handleClose} size='small' outlined>Close</Button>
                  <Button size="small"
                    label="Save"
                    icon="pi pi-check" type="submit" loading={loading} className='ms-2' />
                </div>
              </Form>
            </Dialog>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
