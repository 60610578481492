// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialpad {
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
}
.dialpad .dialpad-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.dialpad .dialpad-grid .dialpad-key {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.dialpad .dialpad-grid .dialpad-key:hover {
  background-color: #f0f0f0;
}
.dialpad .dialpad-grid .dialpad-key:active {
  transform: scale(0.95);
}
.dialpad .dialpad-grid .dialpad-key .number {
  font-size: 1.2em;
  font-weight: 500;
}
.dialpad .dialpad-grid .dialpad-key .letters {
  font-size: 0.7em;
  color: #666;
  margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/Components/LeadCalling/Dialpad.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AACF;AACE;EACE,aAAA;EACA,qCAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AACN;AACM;EACE,yBAAA;AACR;AAEM;EACE,sBAAA;AAAR;AAGM;EACE,gBAAA;EACA,gBAAA;AADR;AAIM;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AAFR","sourcesContent":[".dialpad {\n  margin-top: 20px;\n  padding: 15px;\n  border-radius: 8px;\n  background-color: #f8f9fa;\n\n  .dialpad-grid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 10px;\n    \n    .dialpad-key {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      padding: 10px;\n      background: white;\n      border: 1px solid #e0e0e0;\n      border-radius: 8px;\n      cursor: pointer;\n      transition: all 0.2s ease;\n\n      &:hover {\n        background-color: #f0f0f0;\n      }\n\n      &:active {\n        transform: scale(0.95);\n      }\n\n      .number {\n        font-size: 1.2em;\n        font-weight: 500;\n      }\n\n      .letters {\n        font-size: 0.7em;\n        color: #666;\n        margin-top: 2px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
