import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import Dialpad from './Dialpad';
import { fetchMethod } from '../ApiHeader';
import ApiListFile from '../ApiListFile';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FeatherIcons from 'feather-icons-react';
import { contextVar } from '../ContextVar';

const OutgoingCall = forwardRef((props, ref) => {


    const { API_OUTGOING_CALL_TOKEN } = ApiListFile();
    // const phone_number = localStorage.getItem("phone_number")

    const { setphoneNumber, phoneNumber, setcallStatus,callStatus } = useContext(contextVar)
    // const getValidValue = (value) => value && value !== 'undefined' && value !== 'null' ? value : '';

    const [device, setDevice] = useState(null);
    const [token, setToken] = useState(null);
    const [number, setNumber] = useState('');
    const [status, setStatus] = useState('Make a call');
    const [audioContext, setAudioContext] = useState(null);
    const [isCalling, setIsCalling] = useState(false);
    const [activeCallSid, setActiveCallSid] = useState('');
    const [callObject, setCallObject] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [callStartTime, setCallStartTime] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isBusyMessagePlaying, setIsBusyMessagePlaying] = useState(false);








    // function handleCallStatusUpdate(callSid, callStatus) {
    //     console.log(`Received call status update: Call SID - ${callSid}, Status - ${callStatus}`);
    //     // Handle the call status update as needed
    //     // You can update the UI or trigger other actions based on the call status
    // }
    // Fetch token from the server and setup the Twilio Device
    useEffect(() => {
        if (phoneNumber) {
            fetchMethod("GET", `${API_OUTGOING_CALL_TOKEN}`, {})
                .then(data => {
                    setToken(data.token);
                    handleUserInteraction();
                })
                .catch(error => console.error('Error fetching token:', error));
        }
    }, [API_OUTGOING_CALL_TOKEN]);

    // Initialize the Device when the token is ready
    useEffect(() => {
        if (!token) return;
        const newDevice = new Device(token, { debug: true });
        newDevice.on('ready', () => {
            // setStatus('Ready');
            setDevice(newDevice);
        });

        newDevice.on('error', (error) => {
            setStatus('Unable to connect...');
            setIsCalling(false);
        });

        setDevice(newDevice);
    }, [token]);

    // Ensure the audio context is resumed from a user gesture
    const handleUserInteraction = () => {
        if (!audioContext) {
            const context = new (window.AudioContext || window.webkitAudioContext)();
            context.resume().then(() => {
                setAudioContext(context);
            }).catch(err => console.error("Error resuming AudioContext:", err));
        } else if (audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
            }).catch(err => console.error("Error resuming AudioContext:", err));
        }
    };

    useEffect(() => {
        if (props.callingId) {
            setNumber(props.callingId);
            makeCall();
        }
    }, [props.callingId, device]);

    useEffect(() => {
        let timerInterval;
        if (callStartTime) {
            timerInterval = setInterval(() => {
                setTimer(Math.floor((Date.now() - callStartTime) / 1000));
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [callStartTime]);

    function playBusyMessage() {
        setStatus('The called number is busy. Please try again later.');
        setIsBusyMessagePlaying(true);
        // Simulate the busy message playing duration
        setTimeout(() => {
            setIsBusyMessagePlaying(false);
        }, 5000); // Example busy message duration
    }

    const formatTimer = (seconds) => {
        if (typeof seconds !== 'number' || isNaN(seconds) || seconds < 0) {
            return '00:00'; // Return a default value in case of invalid input
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };


    function makeCall() {
        let callStartTime = null;
        if (isCalling || isBusyMessagePlaying) {
            console.log("A call is already active.");
            return;
        }


        if (device && number.trim() !== '' && audioContext && audioContext.state === 'running') {
            setIsCalling(true);
            const outgoingCall = device.connect({
                params: {
                    To: number,
                    callerId: phoneNumber
                }
            });
            outgoingCall.then(call => {
                setActiveCallSid(call.parameters);

                call.on('accept', () => {
                    setStatus('In Call');
                    setcallStatus('connected')
                    callStartTime = Date.now();
                    setCallStartTime(Date.now());
                });

                // call.on('accept', () => {
                //     console.log('Call accepted by receiver');
                //     setStatus('In Call');
                //     setcallStatus('connected');
                //     setCallStartTime(Date.now());  // Start the timer when the call is accepted
                // });

                setCallObject(call);
                props?.setfromNumber(phoneNumber)
                props?.settoNumber(number)

                call.on('disconnect', (conn) => {
                    setStatus('Call Ended');
                    setcallStatus('Call Ended')
                    setTimeout(() => {
                        props?.setVisible(false);
                    }, 1000);
                    props?.setcallDispositionModal(true)
                    setIsCalling(false);
                    props?.setcallSid(call.parameters)
                    setCallObject(null);
                    setCallStartTime(null);
                    setTimer(0);
                    const callEndTime = Date.now();

                    if (callStartTime === null) {
                        props?.setcallDuration('00:00:00');
                        return;
                    }
                    const durationInMilliseconds = callEndTime - callStartTime;
                    if (durationInMilliseconds < 0) {
                        props?.setcallDuration('00:00:00');
                        return;
                    }

                    // Convert duration from milliseconds to hours, minutes, seconds
                    const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
                    const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);

                    // Format the duration into hh:mm:ss
                    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;


                    props?.setcallDuration(formattedDuration);
                });
                // Listen for other call statuses
                call.on('status', (status) => {
                    if (status === 'busy') {
                        playBusyMessage();
                    }
                });
            }).catch(error => {
                // console.error('Failed to connect the call:', error);
                // // setIsCalling(false);
                // // resetCallState();
                // if (error.message.includes('busy')) {
                //     playBusyMessage();
                // }
                console.error('Failed to connect the call:', error);

                // Detect invalid call scenario
                if (error.message.includes('invalid number') || error.message.includes('failed')) {
                    setStatus('Invalid Call');
                    setcallStatus('invalid');
                    setIsCalling(false);
                    props?.setcallDispositionModal(true); // Optionally show a modal or message to the user
                }

                if (error.message.includes('busy')) {
                    playBusyMessage();
                }
            });
        } else {
            if (!device) setStatus('Device not ready');
            if (!number.trim()) setStatus('Number not specified');
            if (!audioContext || audioContext.state !== 'running') setStatus('Calling...');

        }
    }
console.log("invalid call status",callStatus)
    // function makeCall() {
    //     let callStartTime = null;
    //     if (isCalling || isBusyMessagePlaying) {
    //         console.log("A call is already active.");
    //         return;
    //     }

    //     console.log("Calling", number);
    //     console.log("Caller Id", phoneNumber);

    //     if (device && number.trim() !== '' && audioContext && audioContext.state === 'running') {
    //         setIsCalling(true);
    //         const outgoingCall = device.connect({
    //             params: {
    //                 To: number,
    //                 callerId: phoneNumber
    //             }
    //         });
    //         outgoingCall.then(call => {
    //             console.log('Call initiated', call);
    //             setActiveCallSid(call.parameters);

    //             call.on('accept', () => {
    //                 console.log('Call accepted by receiver');
    //                 setStatus('In Call');
    //                 setcallStatus('connected')
    //                 callStartTime = Date.now();
    //                 setCallStartTime(Date.now());
    //             });

    //             setCallObject(call);
    //             props?.setfromNumber(phoneNumber)
    //             props?.settoNumber(number)

    //             call.on('disconnect', (conn) => {
    //                 console.log('Call has been disconnected by the other party', conn._status);
    //                 setStatus('Call Ended');
    //                 setcallStatus('Call Ended')
    //                 setTimeout(() => {
    //                     props?.setVisible(false);
    //                 }, 1000);
    //                 props?.setcallDispositionModal(true)
    //                 setIsCalling(false);
    //                 props?.setcallSid(call.parameters)
    //                 setCallObject(null);
    //                 setCallStartTime(null);
    //                 setTimer(0);

    //                 const callEndTime = Date.now();
    //                 console.log('Call end time:', callEndTime);
    //                 console.log("start time", callStartTime);

    //                 if (callStartTime === null) {
    //                     console.error('Call start time is not set. Cannot calculate duration.');
    //                     props?.setcallDuration('00:00:00');
    //                     return;
    //                 }
    //                 const durationInMilliseconds = callEndTime - callStartTime;
    //                 if (durationInMilliseconds < 0) {
    //                     console.error('Duration is negative, check system clock.');
    //                     props?.setcallDuration('00:00:00');
    //                     return;
    //                 }

    //                 // Convert duration from milliseconds to hours, minutes, seconds
    //                 const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    //                 const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //                 const seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);

    //                 // Format the duration into hh:mm:ss
    //                 const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    //                 console.log('Call duration:', formattedDuration);


    //                 props?.setcallDuration(formattedDuration);
    //             });
    //             // Listen for other call statuses
    //             call.on('status', (status) => {
    //                 if (status === 'busy') {
    //                     console.log('The called number is busy.');
    //                     playBusyMessage();
    //                 }
    //             });
    //         }).catch(error => {
    //             console.error('Failed to connect the call:', error);
    //             // setIsCalling(false);
    //             // resetCallState();
    //             if (error.message.includes('busy')) {
    //                 playBusyMessage();
    //             }
    //         });
    //     } else {
    //         console.log('Preconditions for call not met:', { device, number, audioContextState: audioContext?.state });
    //         if (!device) setStatus('Device not ready');
    //         if (!number.trim()) setStatus('Number not specified');
    //         if (!audioContext || audioContext.state !== 'running') setStatus('Calling...');

    //     }
    // }


    useImperativeHandle(ref, () => ({
        disconnectCall,
    }));

    function disconnectCall() {
        if (callObject) {
            callObject.disconnect();
            setTimeout(() => {
                props?.setVisible(false);
            }, 1000);

            // props?.setVisible(false);
            props?.setcallSid(callObject.parameters)
            props?.setcallDispositionModal(true)
            setStatus('Call Ended');
            setcallStatus('Call Ended')
            setIsCalling(false);
            setCallStartTime(null);
            setTimer(0);
            const callEndTime = Date.now();

            if (callStartTime === null) {
                props?.setcallDuration('00:00:00');
                return;
            }

            if (callStartTime) {
                const durationInMilliseconds = callEndTime - callStartTime;
                if (durationInMilliseconds < 0) {
                    props?.setcallDuration('00:00:00');
                    return;
                }

                // Convert duration from milliseconds to hours, minutes, seconds
                const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
                const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);

                // Format the duration into hh:mm:ss
                const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                props?.setcallDuration(formattedDuration);
            } else {
                console.error('Call start time is not set. Cannot calculate duration.');
                props?.setcallDuration('00:00:00');
            }

        }

    }

    const handleUnMuteAudio = () => {
        if (callObject) {
            callObject.mute(false);
            setIsMuted(false);
        } else {
            console.log('No active call to unmute audio.');
        }
    };

    const handleMuteAudio = () => {
        if (callObject) {
            callObject.mute(true);
            setIsMuted(true);
        } else {
            console.log('No active call to mute audio.');
        }
    };

    const handleChange = (event) => {
        setNumber(event.target.value);
    };

    const handleDialpadPress = (key) => {
        if (callObject) {
            callObject.sendDigits(key);
        }
    };

    return (
        <div>
            <div className="d-flex mb-4">
                <span><i className="pi pi-user" style={{ color: 'green', background: "#dcffde", padding: '10px 10px', borderRadius: "50%", border: "1px solid #b0ffb4" }}></i></span>
                <div className="ms-3">
                    <h3>{number}</h3>
                    <p>{status} {status === 'In Call' && <span>({formatTimer(timer)})</span>}</p>
                    {isMuted &&
                        <span><FeatherIcons icon={'mic-off'} onClick={handleUnMuteAudio} className="icon-dual call-buttons-unmute cursor-pointer" />
                        </span>
                    }
                    {!isMuted &&
                        <span><i className="pi pi-microphone call-buttons-mute cursor-pointer" onClick={handleMuteAudio}></i></span>}
                    <span><i className="pi pi-phone call-buttons-disconnect cursor-pointer" onClick={disconnectCall}></i></span>
                </div>
            </div>
            {status === 'In Call' && (
                <div className="call-dialpad">
                    <Dialpad onKeyPress={handleDialpadPress} />
                </div>
            )}
        </div>
    );
});

export default OutgoingCall;
