import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView, TabPanel } from 'primereact/tabview';
import "./Calls.scss"
import { contextVar } from '../Components/ContextVar';
import { Skeleton } from 'primereact/skeleton';
import CallRecordTable from './CallRecordTable';
import ApiListFile from '../Components/ApiListFile';
import { fetchMethod } from '../Components/ApiHeader';
import { Form } from 'react-bootstrap';
import OutgoingCall from '../Components/LeadCalling/OutgoingCall';
import CallDisposition from '../Components/LeadCalling/CallDisposition';
import { Dialog } from 'primereact/dialog';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import debounce from 'lodash/debounce';
import { Toast } from "primereact/toast";
import { Tooltip } from 'primereact/tooltip';

const data = [
    {
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
    {
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
    {
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
    {
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
]
const Calls = () => {
    const [visible, setVisible] = useState(false);
    const [filterSidebarVisible, setfilterSidebarVisible] = useState(false)

    const [products, setProducts] = useState([]);
    const [checked, setChecked] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    useEffect(() => {
        setProducts(data)
    }, []);



    const customHeader = (headerText, iconClass) => (
        <>
            <i className={iconClass} style={{ color: 'var(--primary-color)', marginRight: '12px' }}></i>
            {headerText}
        </>
    );

    // ///////////////////////////////////////////////////////////////////////////////

    const { setnetworkError, phoneNumber, userGroup } = useContext(contextVar)
    const { API_CALL_RECORD_COUNT, API_PAGINATED_RECIPIENT, API_GET_RECIPIENT_LIST } = ApiListFile()
    const callRef = useRef();
    const toast = useRef(null);
    const [minDuration, setMinDuration] = useState('');
    const [maxDuration, setMaxDuration] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setloading] = useState(true)
    const [countData, setcountData] = useState('')
    const [countTotal, setcountTotal] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [recipientList, setrecipientList] = useState([])
    const [newCallVisible, setnewCallVisible] = useState(false)
    const [phoneNumberOptions, setPhoneNumberOptions] = useState([]);
    const [fromPhoneNumber, setFromPhoneNumber] = useState('');
    const [dialogState, setDialogState] = useState("normal");
    const [callingId, setcallingId] = useState('');
    const [callDispositionModal, setcallDispositionModal] = useState(false)
    const [callSid, setcallSid] = useState('')
    const [callDuration, setcallDuration] = useState(0)
    const [toNumber, settoNumber] = useState('')
    const [fromNumber, setfromNumber] = useState('')
    const [callPurpose, setCallPurpose] = useState('');
    const [callDirection, setcallDirection] = useState('')
    const [recipientId, setrecipientId] = useState('')

    const [inputValue, setInputValue] = useState('');


    const callPurposeOptions = [
        {
            label: 'Prospecting Call',
            value: 'prospectingCall'
        },
        {
            label: 'Opportunity Call',
            value: 'opportunityCall'
        },
        {
            label: 'Support Call',
            value: 'supportCall'
        },
        {
            label: 'Other Call',
            value: 'otherCall'
        }
    ]

    const callDirectionOptions = [
        {
            label: 'Incoming',
            value: 'incoming'
        },
        {
            label: 'Outgoing',
            value: 'outgoing'
        }
    ]

    const tabHeaders = [
        { label: 'Total', count: (countTotal || 0) },
        // { label: 'Active Calls', count: (countData?.activeCall || 0) },
        { label: 'No Answer', count: (countData?.noAnswer || 0) },
        { label: 'Gatekeeper', count: (countData?.gatekeeper || 0) },
        { label: 'Left Voicemail', count: (countData?.leftVoicemail || 0) },
        { label: 'Busy', count: (countData?.busy || 0) },
        { label: 'Bad/Wrong Number', count: (countData?.['bad/WrongNumber'] || 0) },
        { label: 'Not in service', count: (countData?.notInService || 0) },
        { label: 'Connected - Positive', count: (countData?.['connected-positive'] || 0) },
        { label: 'Connected - Neutral', count: (countData?.['connected-neutral'] || 0) },
        { label: 'Connected - Negative', count: (countData?.['connected-negative'] || 0) },
        { label: 'No Disposition', count: (countData?.noDisposition || 0) },
    ];

    useEffect(() => {
        document.title = "Calls";
    }, []);


    const handleTabChange = (event) => {
        setActiveTab(event.index);
    };

    useEffect(() => {
        fetchCallCount()
    }, [callDispositionModal])

    const fetchCallCount = () => {
        setloading(true)
        fetchMethod("GET", `${API_CALL_RECORD_COUNT}`, {})
            .then((data) => {
                setcountData(data.data)
                setcountTotal(data.total_count)
                setloading(false)
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    setloading(false);
                    return;
                }
            });
    }


    const initiateCall = (phone) => {
        console.log("initiate call", phone)
        setVisible(true);
        setcallingId(phone);
        setInputValue('')
    };

    const formatPhoneNumber = (phone) => {
        // Remove all non-digit characters
        let cleaned = phone.replace(/\D/g, '');
        
        // If number starts with a plus, we'll assume it's already got a country code
        if (phone.startsWith('+')) {
            return '+' + cleaned;
        }
        
        // If no plus, add +1 as default country code
        return '+1' + cleaned;
    };

    const handleCreate = () => {
        // Format the phone number
        const formattedNumber = formatPhoneNumber(inputValue);
        
        // Basic validation - should have at least 10 digits after formatting
        if (formattedNumber.length < 11) { // +1 plus at least 10 digits
            toast.current.show({
                severity: "warn",
                summary: "Error",
                detail: "Please enter a valid phone number with at least 10 digits",
            });
            return;
        }

        const newOption = {
            label: formattedNumber,
            value: formattedNumber,
            phone: formattedNumber,
            isNew: true
        };

        setrecipientList(prevList => [...prevList, newOption]);
        setSelectedValue(newOption);
        initiateCall(formattedNumber);
        setInputValue('')
    };

    useEffect(() => {
        if (phoneNumber) {
            setPhoneNumberOptions([{ label: phoneNumber, value: phoneNumber }]);
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (phoneNumberOptions.length > 0 && !fromPhoneNumber) {
            setFromPhoneNumber(phoneNumberOptions[0].value);
        }
    }, [phoneNumberOptions, fromPhoneNumber]);

    const handleMaximize = () => {
        setDialogState("maximized");
        document.body.style.overflow = 'visible'
    };

    const handleRestore = () => {
        setDialogState("normal");
        document.body.style.overflow = 'hidden'
    };

    const handlePhoneNumberChange = (selectedOption) => {
        setFromPhoneNumber(selectedOption.value);
    };

    const handleNewCall = () => {
        { newCallVisible ? setnewCallVisible(false) : setnewCallVisible(true) }
        fetchRecipients()
    }

    useEffect(() => {
        if (callDispositionModal == false) {
            setnewCallVisible(false)
            setInputValue('')
            setrecipientId('')
            setSelectedValue(null)
        }
    }, [callDispositionModal])



    const handleToNumbrChange = (selectedOptions) => {
        console.log("handleToNumbrChange", selectedOptions)
        if (selectedOptions && selectedOptions.length > 0) {
            const lastSelectedOption = selectedOptions[selectedOptions.length - 1];
            const phone = lastSelectedOption?.value?.phone;
            setSelectedValue(selectedOptions);
            setrecipientId(lastSelectedOption?.value?._id)
            initiateCall(phone);
        } else {
            setSelectedValue(null);
            setrecipientId('')
            setVisible(false);
            setcallingId(null);
        }
    };


    const closeCallModal = () => {
        if (callRef.current) {
            callRef.current.disconnectCall();
        }
        setVisible(false)
        setDialogState("normal")
        fetchCallCount()
        setnewCallVisible(false)
        setSelectedValue(null)
        fetchRecipients()
    }

    const isValidPhoneNumber = (phone) => {
        // Check if the phone number is defined and doesn't contain letters
        if (!phone || /[a-zA-Z]/.test(phone)) {
            return false;
        }

        // Match and extract country code and the rest of the number
        const phoneRegex = /^(\+?\d{1,3})(\d{5,})$/; // Country code can be + or digits followed by at least 5 digits for the phone number
        const match = phone.match(phoneRegex);

        // If match exists, ensure it is not just a country code (e.g., "+91")
        if (match) {
            const countryCode = match[1]; // Extract country code part (e.g., "+91")
            const numberPart = match[2]; // Extract actual number part (e.g., "7209805")

            // Ensure that the number part has at least some digits beyond the country code
            return numberPart.length > 5; // Adjust the number length to suit your needs
        }

        return false; // If phone doesn't match the required format
    };


    const fetchRecipients = async (searchTerm) => {

        setloading(true);
        setnetworkError(false);

        try {
            const response = await fetchMethod("POST", API_PAGINATED_RECIPIENT, { search_query: searchTerm });

            if (response.status === "success") {
                const recipientsWithValidPhone = response?.items.filter(item => isValidPhoneNumber(item.phone));
                setrecipientList(recipientsWithValidPhone);
            } else if (response.status === "error") {
                console.error("API returned error status");
            }
        } catch (error) {
            console.error(error);
            if (error instanceof TypeError) {
                setnetworkError(true);
            }
        } finally {
            setloading(false);
        }
    };


    const handleInputChange = (newValue) => {
        // fetchRecipients(newValue)
        setInputValue(newValue);

    };



    return (
        <>
            <Toast ref={toast} />
            <div className='content-page calls-main'>
                <div className='container-fluid mt-3'>
                    <div className="d-flex">

                        {filterSidebarVisible && <div className='card call-leftbar' visible={visible} onHide={() => setVisible(false)}>
                            <h2>Filters</h2>
                            <div>
                                <InputText id="search" className="p-inputtext-sm w-100" placeholder="Search calls..." />
                            </div>
                            <div className='d-flex justify-content-between  align-items-center my-3'>
                                <label htmlFor="activeCalls">Show active calls</label>
                                <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                            </div>


                            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                <AccordionTab header={customHeader("Leads", "pi pi-users")} className='accordian-left'>
                                    <CreatableSelect
                                        value={recipientList.find(option => option.phone === selectedValue?.phone) || null}
                                        onChange={handleToNumbrChange}
                                        options={recipientList.map(option => ({
                                            label: `${option.first_name} ${option.last_name} (${option.phone})`,
                                            value: option
                                        }))}
                                        onInputChange={handleInputChange}
                                        isLoading={loading}
                                        placeholder="Search leads..."
                                        onCreateOption={handleCreate}
                                    />
                                </AccordionTab>
                                <AccordionTab header={customHeader("Purpose", "pi pi-users")}>
                                    <Dropdown
                                        name="callPurpose"
                                        value={callPurpose}
                                        onChange={(e) => setCallPurpose(e.target.value)}
                                        options={callPurposeOptions}
                                        placeholder="Select a purpose"
                                        className="w-full col-md-12"
                                    />
                                </AccordionTab>
                                <AccordionTab header={customHeader("Direction", "pi pi-users")}>
                                    <Dropdown
                                        name="callPurpose"
                                        value={callDirection}
                                        onChange={(e) => setcallDirection(e.target.value)}
                                        options={callDirectionOptions}
                                        placeholder="Select a purpose"
                                        className="w-full col-md-12"
                                    />
                                </AccordionTab>
                                <AccordionTab header={customHeader("Date", "pi pi-users")}>
                                    <div className='d-flex align-items-center gap-2'>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            placeholderText="From"
                                            className='w-100'
                                        />
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            placeholderText="To"
                                            className='w-100 '
                                        />
                                    </div>

                                </AccordionTab>
                                <AccordionTab header={customHeader("Duration", "pi pi-users")}>
                                    <div className="duration-input-container">
                                        <h6>Duration (seconds)</h6>
                                        <div className='d-flex align-items-center gap-2'>
                                            <input
                                                type="number"
                                                value={minDuration}
                                                onChange={(e) => setMinDuration(e.target.value)}
                                                placeholder="Min"
                                                className='w-100 '
                                            />
                                            <span className="duration-separator">-</span>
                                            <input
                                                type="number"
                                                value={maxDuration}
                                                onChange={(e) => setMaxDuration(e.target.value)}
                                                placeholder="Max"
                                                className='w-100 '
                                            />
                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab header={customHeader("Contact Lists", "pi pi-users")}>
                                    <p className="m-0">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                                        quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                        mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                                    </p>
                                </AccordionTab>
                            </Accordion>

                        </div>}


                        <div className='card call-RightPannel'>
                            <div>
                                <div className='d-flex justify-content-right'>
                                    {/* <div>
                                    <Button icon="pi pi-align-left" className='filter-btn me-2' label="Filter" severity="secondary" size='small' outlined onClick={() => setfilterSidebarVisible(!filterSidebarVisible)} />
                                </div> */}
                                    <div>
                                        {userGroup?.twilio?.make_call?.R && (
                                            <div style={{ position: 'relative', display: 'inline-block' }} id="new-call-wrapper">
                                                <div className="new-call-button-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                                                    <Button
                                                        label="New Call"
                                                        icon=""
                                                        className="mr-2"
                                                        aria-controls="popup_menu_right"
                                                        size='small'
                                                        aria-haspopup
                                                        onClick={handleNewCall}
                                                        disabled={!phoneNumber}
                                                    />
                                                </div>
                                                {/* Conditionally show tooltip when the button is disabled */}
                                                {!phoneNumber && (
                                                    <Tooltip target=".new-call-button-wrapper" content="You need to purchase a number before making a call." position="top" />
                                                )}
                                            </div>
                                        )}

                                    </div>
                                    {newCallVisible &&
                                        <div className='card p-2'>
                                            <Form>
                                                <div className="row">
                                                    <Form.Group controlId="" className="mb-3" size="">
                                                        <Form.Label className="w-100">From</Form.Label>
                                                        <Dropdown
                                                            name="callFrom"
                                                            value={fromPhoneNumber}
                                                            onChange={handlePhoneNumberChange}
                                                            options={phoneNumberOptions}
                                                            optionLabel="label"
                                                            placeholder="Select"
                                                            className="w-full col-md-12"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="row">
                                                    <Form.Group controlId="" className="mb-3" size="">
                                                        <Form.Label className="w-100">To</Form.Label>
                                                        <CreatableSelect
                                                            isMulti
                                                            value={recipientList.find(option => option.phone === selectedValue?.phone) || null}
                                                            onChange={handleToNumbrChange}
                                                            options={recipientList.map(option => ({
                                                                label: `${option.first_name} ${option.last_name} (${option.phone})`,
                                                                value: option
                                                            }))}
                                                            onInputChange={handleInputChange}
                                                            isLoading={loading}
                                                            placeholder="Select"
                                                            onCreateOption={handleCreate}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Form>
                                        </div>
                                    }
                                </div>
                                {loading ? (
                                    <>
                                        <div className="skeleton-container" style={{ display: 'flex' }}>
                                            {tabHeaders.map((header, index) => (
                                                <div key={index} style={{ marginRight: '20px' }}>
                                                    <Skeleton height="40px" width="100px" />
                                                </div>
                                            ))}
                                        </div>
                                        <div className='mt-4'>
                                            <CallRecordTable />
                                        </div>
                                    </>
                                ) : (
                                    <div >
                                        <TabView activeIndex={activeTab} onTabChange={handleTabChange} scrollable>
                                            {tabHeaders?.map((items, index) => (
                                                <TabPanel
                                                    key={index}
                                                    header={<>{items?.label} <span>({items?.count})</span></>}
                                                    style={index === tabHeaders.length - 1 ? { paddingRight: '50px' } : {}}
                                                >
                                                    <CallRecordTable activeTab={index} fetchCallCount={fetchCallCount} callDispositionModal={callDispositionModal} />
                                                </TabPanel>
                                            ))}
                                        </TabView>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <Dialog
                        header={
                            <div style={{ marginTop: '-0.5rem' }} className="d-flex justify-content-between align-items-center ">
                                <h5 className='mt-1'>Call</h5>
                                <div className="d-flex align-items-center">
                                    {dialogState === "normal" && (
                                        <>
                                            <Button
                                                icon="pi pi-window-minimize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleMaximize}
                                            />
                                        </>
                                    )}
                                    {dialogState === "minimized" && (
                                        <Button
                                            icon="pi pi-window-maximize"
                                            className="p-mr-2"
                                            text
                                            onClick={handleRestore}
                                        />
                                    )}
                                    {dialogState === "maximized" && (
                                        <Button
                                            icon="pi pi-window-maximize"
                                            className="p-mr-2"
                                            text
                                            onClick={handleRestore}
                                        />
                                    )}
                                    <span style={{ width: '8px' }}></span>
                                </div>
                            </div>
                        }
                        visible={visible}
                        modal={true}
                        // onHide={() => setVisible(false)}
                        onHide={closeCallModal}
                        style={
                            dialogState === "normal"
                                ? { width: "25vw" }
                                : dialogState === "minimized"
                                    ? { display: "none" }
                                    : { width: "18vw", height: "8vh", marginRight: "80px", marginBottom: "0" }
                        }

                        // position="bottom-right"

                        position={
                            dialogState === "normal"
                                ? "top-right"
                                : dialogState === "minimized"
                                    ? ""
                                    : "bottom-right"
                        }
                        draggable={false}
                        resizable={false}
                        className={
                            dialogState === "normal"
                                ? "dialog-top-right"
                                : dialogState === "minimized"
                                    ? ""
                                    : "dialog-bottom-right"

                        }
                    >
                        <OutgoingCall ref={callRef} setVisible={setVisible} callingId={callingId} setcallDispositionModal={setcallDispositionModal} setcallSid={setcallSid} setcallDuration={setcallDuration} settoNumber={settoNumber} setfromNumber={setfromNumber} />
                    </Dialog>
                    {callDispositionModal === true && <CallDisposition callDispositionModal={callDispositionModal} setcallDispositionModal={setcallDispositionModal} callSid={callSid} callDuration={callDuration} toNumber={toNumber} fromNumber={fromNumber} recipientId={recipientId} />}
                </div>
            </div>
        </>
    )
}

export default Calls