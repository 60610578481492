import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Modal, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import "react-quill/dist/quill.snow.css"; // import the styles
import { Button } from "primereact/button";
import TemplateEditor from "../utils/TemplateEditor";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "./Template.scss"
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import template1HTML from './domytemplate/DomyTemplate';
import template2HTML from './domytemplate2/domyTemplate2';
import template3HTML from './domytemplate3/DomyTemplate3';
import Footer from "../Components/Footer/Footer";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import { Dropdown } from "primereact/dropdown";
import { Menu } from 'primereact/menu';
import { InputTextarea } from "primereact/inputtextarea";
import { Skeleton } from "primereact/skeleton";
import AWS from "aws-sdk";
import { Tooltip } from "primereact/tooltip";
// import 'primereact/resources/themes/sasha/theme.css'; // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import DOMPurify from 'dompurify';

const AddTemplate = () => {
    const { templateType, id } = useParams()
    const [currentTemplate, setCurrentTemplate] = useState({});
    const [loader, setloader] = useState(false);
    const [signature, setSignature] = useState("")
    const toast = useRef(null);
    const menuLeft = useRef(null);
    const { setnetworkError, userGroup, userCredential, setplanVisibleModal, setplanUpgradeMessage } = useContext(contextVar)
    const [activeContent, setActiveContent] = useState(null);
    const [aiAssistant, setAiAssistant] = useState(false);
    const [generateLoading, setGenerateLoading] = useState(false);
    const [cardSelect, setCardSelect] = useState('')
    const [contentCardSelect, setcontentCardSelect] = useState('')
    const [errMessage, seterrMessage] = useState('')
    const [generatedEmail, setgeneratedEmail] = useState([])
    const [generatedEmailSubject, setgeneratedEmailSubject] = useState([])
    const [selectedText, setSelectedText] = useState('');
    const [isSubject, setisSubject] = useState(false)
    const navigate = useNavigate()
    const {
        API_DISPLAY_SIGNATURE,
        API_TEMPLATE,
        API_GET_RECIPIENT_LIST,
        API_GENERATE_EMAIL,
        API_REPHRASE_TEXT,
        API_TEMPLATE_BY_ID
    } = ApiListFile()

    // State for validation messages
    const [validation, setValidation] = useState({
        template_name: "",
        template_subject: "",
        template_body: ""
    });

    const [recipientList, setrecipientList] = useState()
    const [selectedRecipientList, setselectedRecipientList] = useState('')
    const [emailPrompt, setemailPrompt] = useState('')
    const [rephrasePrompt, setrephrasePrompt] = useState('')
    const [selectedFile, setselectedFile] = useState([])



    useEffect(() => {
        fetchRecipientList()
    }, [])

    const fetchRecipientList = () => {
        fetchMethod("GET", `${API_GET_RECIPIENT_LIST}`, {})
            .then((data) => {
                const recipientOptions = data.items.map((recipient) => ({
                    label: recipient.first_name + " " + recipient.last_name + " (" + recipient.email + ")",
                    value: recipient,
                }));
                setrecipientList(recipientOptions)
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                }
            });
    }

    useEffect(() => {
        fetchTemplateById();
        setloader(true)
    }, [id]);
    const fetchTemplateById = () => {
        if (id != 0) {
            fetchMethod("GET", `${API_TEMPLATE_BY_ID}/${id}`, {})
                .then((data) => {
                    console.log("template data by id", data)
                    const fetchedTemplate = data?.template;
                    setCurrentTemplate(fetchedTemplate);
                    setselectedFile(fetchedTemplate.file_links);
                    setloader(false)
                })
                .catch((error) => {
                    console.error(error);
                    setloader(false)
                    if (error instanceof TypeError) {
                        console.log("Network error occurred");
                    }
                });
        }
    };


    useEffect(() => {
        fetchMethod("GET", `${API_DISPLAY_SIGNATURE}`)
            .then((data) => {
                let htmlString = data.signature;

                // This regular expression matches the start of the first tag after potentially leading whitespace
                let modifiedHtmlString = htmlString?.replace(/(^\s*<\w+\b)(?!.*\bid=)/, '$1 id="signature_start"');

                // Now, data.signature has the modified HTML string
                data.signature = modifiedHtmlString;
                if (data.signature) {
                    setSignature(data.signature)
                } else {
                    setSignature("")
                }
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    console.log("Network error occurred");
                    setnetworkError(true)
                    return;
                }
            });
    }, [])

    useEffect(() => {
        handleAddTemplate()
    }, [templateType, signature])

    const handleAddTemplate = () => {
        let template_body
        if (templateType == 'template1') {
            template_body = template1HTML
        }
        if (templateType == 'template2') {
            template_body = template2HTML
        }
        if (templateType == 'template3') {
            template_body = template3HTML
        }
        if (templateType == 'newTemplate') {
            template_body = ''
        }
        if (templateType != 'editTemplate') {
            let newValidation = { template_name: "", template_subject: "", template_body: "" };
            setValidation(newValidation);
            setCurrentTemplate({ template_body: template_body + signature || signature });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!(value.length === 1 && value === " ")) {
            setCurrentTemplate((prevTemplate) => ({ ...prevTemplate, [name]: value }));
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text/plain');
        const sanitizedText = pastedText?.trim()?.replace(/\s+/g, ' '); // Trim and replace multiple spaces with a single space
        document.execCommand('insertText', false, sanitizedText);
    };

    const handleEmailValue = (e) => {
        const value = e.target.value;
        if (value.startsWith(" ")) {
            return;
        }
        setemailPrompt(value);
    };
    const handleRephraseValue = (event) => {
        setrephrasePrompt(event.target.value)
    }


    const handleTextSelection = (selectedText) => {
        console.log("clipboard data", selectedText)

        setSelectedText(selectedText);
        setisSubject(false)
    }
    const handleEditorChange = (text) => {

        // Remove any HTML tags and trim the text
        const strippedText = text.replace(/<[^>]*>/g, '').trim();

        // Update the state
        setCurrentTemplate({ ...currentTemplate, template_body: text });


    };


    const handleFileUpload = (result) => {
        console.log("result file", result)
        let filesArray = [];
        if (result instanceof FileList) {
            filesArray = Array.from(result);  // Convert FileList to array
        } else if (result instanceof File) {
            filesArray = [result];  // If a single file is uploaded
        } else if (Array.isArray(result)) {
            filesArray = result;  // If an array of files is uploaded
        }

        // Now, add these new files to the previous selected files
        setselectedFile(prevFiles => [...prevFiles, ...filesArray]);
    }
    console.log("selectedFile", selectedFile)

    const removeFile = (fileToRemove) => {
        setselectedFile((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };


    const handleUploadToS3 = async () => {
        setGenerateLoading(true);
        const results = [];
        for (const file of selectedFile) {
            const s3 = new AWS.S3();
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: file.name,
                Body: file,
            };
            try {
                const result = await s3.upload(params).promise();

                results.push(result);
            } catch (err) {
                console.error('Error uploading file: ', err);
            }
        }
        handleSave(results)
    };

    const handleSave = async (file) => {
        console.log("result save", file)
        setloader(true);

        let isValid = true;
        let newValidation = { template_name: "", template_subject: "", template_body: "" };

        // Validate the name field
        if (!currentTemplate.template_name) {
            isValid = false;
            newValidation.template_name = "Template name is required";
            setloader(false);
            setGenerateLoading(false);
        }

        // Validate the subject field
        if (!currentTemplate.template_subject) {
            isValid = false;
            newValidation.template_subject = "Template subject is required";
            setloader(false);
            setGenerateLoading(false);
        }


        console.log("clean body", currentTemplate.template_body)


        let cleanBody = currentTemplate.template_body || '';
        const nonWhitespaceRegex = /[^\s]/;
        // const signatureRegex = /<(table|figure)[^>]*(id="signature_start"|class="table")[\s\S]*?<\/\1>/g;

        // Remove signature
        // cleanBody = cleanBody.replace(signatureRegex, '');

        // Extract image source if present
        const imgSrcRegex = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/i;
        const imgMatch = cleanBody.match(imgSrcRegex);
        let imgSrc = '';

        if (imgMatch && imgMatch[1]) {
            imgSrc = imgMatch[1];
        }

        // Remove HTML tags and trim
        cleanBody = cleanBody.replace(/<\/?[^>]+(>|$)/g, '');
        cleanBody = cleanBody.replace(/&nbsp;/g, '');
        cleanBody = cleanBody.trim();


        // Check if the body is blank or consists only of whitespace, line breaks, or spaces after stripping tags
        if ((!cleanBody || !nonWhitespaceRegex.test(cleanBody)) && !imgSrc) {
            isValid = false;
            newValidation.template_body = "Template body is required";
            setloader(false);
            setGenerateLoading(false);
        } else {
            // If there's an image source but no text content, use the image source as the body
            if (!cleanBody && imgSrc) {
                cleanBody = imgSrc;
            }
        }

        setValidation(newValidation);

        if (isValid) {
            const method = id != 0 ? "PUT" : "POST";
            const url = id != 0 ? `${API_TEMPLATE}/${id}` : `${API_TEMPLATE}`;
            // Create a shallow copy of currentTemplate to avoid mutating the original object
            let templateData = { ...currentTemplate };

            // Remove the file_links key from the copied templateData
            delete templateData.file_links;
            const mergedFiles = [
                ...(currentTemplate.file_links || []), // Existing file_links (if any)
                ...file                                // New file data being uploaded
            ];
            const payload = {
                ...templateData,
                creation_date: new Date().toISOString(),
                file: mergedFiles
            };
            console.log("payload", payload)
            fetchMethod(method, url, payload)
                .then((data) => {
                    if (data.status === "success") {
                        toast.current.show({
                            severity: "success",
                            summary: "Success",
                            detail: data.message,
                        });

                        // Delay navigation to allow the toast to display
                        setTimeout(() => {
                            navigate(`/templates`);
                        }, 1000); // Adjust the delay as needed

                    } else {
                        toast.current.show({
                            severity: "warn",
                            summary: "Error",
                            detail: data.message,
                        });
                    }
                    setselectedFile([]);
                    setloader(false);
                    setGenerateLoading(false);

                })
                .catch((error) => {
                    console.error(error);
                    if (error instanceof TypeError) {
                        console.log("Network error occurred");
                        setnetworkError(true);
                        setloader(false);
                        return;
                    }
                });
        }
    };



    const handleGenerateEmail = () => {
        const planStatus = userCredential?.plan_status; // Ensure planStatus is defined
        // if (((planStatus === 'Free' || !planStatus) && userCredential?.ai_generated_word >= 10000)|| (planStatus === 'Basic' && userCredential?.ai_generated_word >=  250000)) {
        //     setplanVisibleModal(true);
        //     setplanUpgradeMessage("You have reached the maximum number of word limit for your plan. Please upgrade your plan for using AI Assisted Email Writing.",)
        //     return; // Prevent further execution
        // }
        setGenerateLoading(true);
        setCardSelect('')
        setcontentCardSelect('')
        fetchMethod("POST", `${API_GENERATE_EMAIL}`, { prompt: emailPrompt })
            .then((data) => {
                if (data.status == "success") {
                    setgeneratedEmail(data.body_suggestions);
                    setgeneratedEmailSubject(data?.subject_suggestions)
                    seterrMessage('')

                }
                if (data.status == "error") {
                    seterrMessage(data?.message)
                    setgeneratedEmail([]);
                    setgeneratedEmailSubject([])
                }
                if (data.status == "warning") {
                    setplanVisibleModal(true);
                    setplanUpgradeMessage(data?.message)
                    setgeneratedEmail([]);
                    setgeneratedEmailSubject([])
                }

                setGenerateLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setGenerateLoading(false)
            });
    };

    const items = [
        {
            label: 'AI assistant',
            items: [
                {
                    label: 'Generate email',
                    icon: 'pi pi-envelope',
                    command: () => {
                        setActiveContent('refreshContent');
                        setAiAssistant(true);
                        setemailPrompt('')
                        seterrMessage('')
                        setgeneratedEmail([])
                        setgeneratedEmailSubject([])
                    }
                },
                {
                    label: 'Rephrase selection',
                    icon: 'pi pi-user-edit',
                    command: () => {
                        setActiveContent('exportContent');
                        setAiAssistant(true);
                        setSelectedText()
                        seterrMessage('')
                        setrephrasePrompt('')
                    }
                },
            ],
        },
    ];

    const handleSelect = (subjectIndex, subject) => {
        setCardSelect(subjectIndex)
        setCurrentTemplate({ ...currentTemplate, template_subject: subject });
    }

    const handleOnSelect = (event) => {
        const input = event.target;
        const selectedText = input.value.substring(input.selectionStart, input.selectionEnd);

        if (selectedText) {
            navigator.clipboard.writeText(selectedText).then(() => {
                setSelectedText(selectedText);
                setisSubject(true)
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }
    };
    const handleSelectContent = (bodyIndex, body) => {
        confirmDialog({
            header: "Do you want to replace the email body ?",
            acceptClassName: "p-button-danger",
            accept: () => {
                setCurrentTemplate({ ...currentTemplate, template_body: body + signature || signature });
            },
            reject: () => {
                setcontentCardSelect('');
            },
            onHide: () => {
                setcontentCardSelect('');
            },
            draggable: false
        });
        setcontentCardSelect(bodyIndex);
    }

    const rephraseSuggestion = [
        {
            "text": "Make it shorter.",
        },
        {
            "text": "Make it longer.",
        },
        {
            "text": "Simplify",
        },
        {
            "text": "Break into bullet points.",
        },
        {
            "text": "Make it sound professional.",
        },
        {
            "text": "Make it sound confident.",
        }
    ]

    const handleRephraseText = (prompt) => {
        const planStatus = userCredential?.plan_status;
        if (((planStatus === 'Free' || !planStatus) && userCredential?.ai_generated_word >= 10000) || (planStatus === 'Basic' && userCredential?.ai_generated_word >= 250000) || (planStatus === 'Basic' && userCredential?.ai_generated_word >= 800000)) {
            setplanVisibleModal(true);
            setplanUpgradeMessage("You have reached the maximum number of word limit for your plan. Please upgrade your plan for using AI Assisted Email Writing.",)
            return; // Prevent further execution
        }
        setGenerateLoading(true);
        setrephrasePrompt(prompt);
        fetchMethod("POST", `${API_REPHRASE_TEXT}`, { text: selectedText, prompt: prompt })
            .then((data) => {
                if (data.status === "success") {
                    const rephrasedText = data?.rephrased_text;
                    console.log("rephrasedText:", rephrasedText);
                    if (isSubject) {
                        // Update the subject with rephrased text
                        setCurrentTemplate((prevTemplate) => ({
                            ...prevTemplate,
                            template_subject: rephrasedText,
                        }));
                    } else {
                        // Update the body with rephrased text
                        const updatedTemplateBody = currentTemplate.template_body.replace(selectedText, rephrasedText);
                        setCurrentTemplate((prevTemplate) => ({
                            ...prevTemplate,
                            template_body: updatedTemplateBody,
                        }));
                    }

                    // Clear the selection in the browser
                    const selection = window.getSelection();
                    if (selection) {
                        selection.removeAllRanges();
                    }

                    // Reset state
                    setSelectedText('');
                    setrephrasePrompt('');
                    seterrMessage('');
                } if (data.status === "error") {
                    seterrMessage(data?.message);
                    setSelectedText('');
                }
                if (data.status == "warning") {
                    setplanVisibleModal(true);
                    setplanUpgradeMessage(data?.message)
                    setSelectedText('');
                }

                setGenerateLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setGenerateLoading(false);
            });
    }

    // Clear selection in TemplateEditor on rephrase completion
    useEffect(() => {
        if (!selectedText) {
            const editor = document.querySelector('.tox-tinymce'); // Change this to your editor's class or ID
            if (editor) {
                const selection = window.getSelection();
                if (selection) {
                    selection.removeAllRanges();
                }
            }
        }
    }, [selectedText]);

    const goBack = () => {
        if (templateType == 'template1' || templateType == 'template2' || templateType == 'template3') {
            navigate(`/pre-template`)
        }
        if (templateType == 'newTemplate' || templateType == 'editTemplate') {
            navigate(`/templates`)
        }
    }
    return (
        <div>
            <Container fluid>
                <ConfirmDialog draggable={false} resizable={false} />
                <div className="content-page">
                    <Button className='me-4 mt-3 cursor-pointer gobackArrow border-0 background-transparent p-0' onClick={goBack} ><i className="pi pi-arrow-left " /></Button>
                    <div className="row">
                        <div className={`col-12 col-lg-${aiAssistant === false ? '6' : '4'} mt-3`}>
                            <div className="card">
                                <div className="card-body position-relative">
                                    {/* <ConfirmDialog /> */}
                                    <Toast ref={toast} />

                                    <Form>
                                        <Form.Group controlId="formTemplateName" className="mb-3">
                                            <Form.Label>Template Name <span className="text-danger">*</span></Form.Label>
                                            {loader && templateType === 'editTemplate' ?
                                                <Skeleton height="40px"></Skeleton>
                                                :
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter template name"
                                                    name="template_name"
                                                    value={currentTemplate.template_name || ""}
                                                    onChange={handleChange}
                                                    onPaste={handlePaste}
                                                    maxLength={100}
                                                />
                                            }
                                            <div className="text-danger">{validation.template_name}</div>
                                        </Form.Group>
                                        <Form.Group controlId="formTemplateSubject" className="mb-3">
                                            <Form.Label >Template Subject <span className="text-danger">*</span></Form.Label>
                                            {loader && templateType === 'editTemplate' ?
                                                <Skeleton height="40px"></Skeleton>
                                                :
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter template subject"
                                                    name="template_subject"
                                                    value={(currentTemplate.template_subject || "").replace(/"/g, '')}
                                                    onChange={handleChange}
                                                    onPaste={handlePaste}
                                                    onSelect={(e) => handleOnSelect(e, 0, e.target.value)}

                                                />
                                            }
                                            <div className="text-danger">{validation.template_subject}</div>
                                        </Form.Group>
                                        <Form.Group controlId="formTemplateBody" className="mb-5">


                                            <Form.Label>Template Body  <span className="text-danger">*</span></Form.Label>
                                            {loader && templateType === 'editTemplate' ?
                                                <Skeleton height="400px"></Skeleton>
                                                :
                                                <TemplateEditor
                                                    onTextSelection={handleTextSelection}
                                                    template_body={currentTemplate.template_body}
                                                    onEditorChange={handleEditorChange}
                                                    onFileUpload={handleFileUpload}
                                                />
                                                // <TemplateEditor
                                                //     onTextSelection={handleTextSelection}
                                                //     template_body={currentTemplate.template_body}
                                                //     onEditorChange={handleEditorChange}
                                                //     onFileUpload={handleFileUpload}
                                                // />

                                            }
                                            <div className="text-danger mt-1">{validation.template_body}</div>
                                        </Form.Group>


                                        <Form.Text style={{ fontSize: "14px" }} className="text-muted mt-5">
                                            {selectedFile.map((file) => (
                                                <div key={file.key} className="relative">
                                                    <h6>
                                                        <span
                                                            className="cursor-pointer"
                                                            data-pr-tooltip={file.Location}
                                                            data-pr-position="top"
                                                        >
                                                            {file?.name || file.key}
                                                        </span>
                                                        <i
                                                            className="pi pi-times p-1 cursor-pointer"
                                                            onClick={() => removeFile(file)}
                                                        ></i>
                                                    </h6>
                                                    <Tooltip target="[data-pr-tooltip]" content={<a href={file.Location} target="_blank" rel="noopener noreferrer">{file.Location}</a>} />
                                                </div>
                                            ))}

                                            {/* {selectedFile.map((file) => (
                                                <h6>{file?.name || file.key}<i className="pi pi-times p-1 cursor-pointer" onClick={() => removeFile(file)}></i></h6>
                                            ))} */}
                                            <b>Note</b>: You can use {`{{first_name}}`}, {`{{last_name}}`},{" "}
                                            {`{{company_name}}`}, {`{{email}}`}, {`{{phone}}`} and {`{{unsubscribe}}`}  as
                                            placeholders for recipient details.
                                        </Form.Text>
                                    </Form>
                                    <div className="ai-assistant-btn">
                                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                                        {userGroup?.template?.generate_email?.A &&
                                            <Button
                                                label="AI assistant"
                                                size="small"
                                                icon="pi pi-star"
                                                className="mr-2"
                                                onClick={(event) => menuLeft.current.toggle(event)}
                                                aria-controls="popup_menu_left"
                                                aria-haspopup
                                                rounded
                                            />}

                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            size="small"
                                            label="Save"
                                            icon="pi pi-check"
                                            onClick={handleUploadToS3}
                                            loading={generateLoading}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`col-12 col-lg-${aiAssistant === false ? '6' : '4'} mt-3`}>
                            <div className="card">
                                <div className="card-body" style={{ height: '88vh', overflow: 'auto' }}>
                                    <h6>Generate Preview for Contact (optional)</h6>
                                    {loader && templateType === 'editTemplate' ?
                                        <Skeleton height="40px"></Skeleton>
                                        :
                                        <div className="card flex justify-content-center">
                                            <Dropdown
                                                value={selectedRecipientList}
                                                onChange={(e) => setselectedRecipientList(e.value)}
                                                showClear={!!selectedRecipientList}
                                                options={recipientList}
                                                optionLabel="label"
                                                placeholder="Select a contact"
                                                className="w-full triggerDropdown"
                                            />
                                        </div>
                                    }
                                    {loader && templateType === 'editTemplate' ?
                                        <div className="mt-2"><Skeleton height="40px"></Skeleton></div>
                                        :
                                        <p className="mt-2">To:  {selectedRecipientList ? `${selectedRecipientList.first_name || ''} ${selectedRecipientList.last_name || ''} <${selectedRecipientList.email || ''}>` : "Example Contact <example@google.com>"} </p>
                                    }
                                    {loader && templateType === 'editTemplate' ?
                                        <div className="mt-2"><Skeleton height="40px"></Skeleton></div>
                                        :
                                        <p>Subject: {(currentTemplate.template_subject || "").replace(/"/g, '')}</p>
                                    }

                                    {loader && templateType === 'editTemplate' ? (
                                        <div className="mt-2"><Skeleton height="400px" /></div>
                                    ) : (
                                        <div className="template-body-container">
                                            {currentTemplate && currentTemplate.template_body ? (
                                                currentTemplate.template_body.trim() === '' ? (
                                                    // If template_body is empty or only whitespace, render a placeholder or image
                                                    <div className="empty-template-body">
                                                        <img src="/path/to/default/image.jpg" alt="Default template image" />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="signature_container ck-content" // Added ck-content class
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(  // Added DOMPurify for security
                                                                currentTemplate.template_body
                                                                    .replace(/{{first_name}}/g, selectedRecipientList?.first_name || '{{first_name}}')
                                                                    .replace(/{{last_name}}/g, selectedRecipientList?.last_name || '{{last_name}}'),
                                                                {
                                                                    ADD_TAGS: ['ul', 'ol', 'li'], // Ensure these tags are allowed
                                                                    ADD_ATTR: ['class', 'style']  // Allow classes and styles
                                                                }
                                                            )
                                                        }}
                                                    />

                                                )
                                            ) : (
                                                <p>No template content available.</p>
                                            )}
                                        </div>

                                    )}
                                </div>
                            </div>

                        </div>
                        <div className={`col-12 col-lg-${aiAssistant === false ? '6' : '4'} mt-3`}>
                            {activeContent === 'refreshContent' && (
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="content-div">
                                                <div className="d-flex justify-content-between">
                                                    <h5>Generate Email Content</h5>
                                                    <i className="pi pi-times cursor-pointer" onClick={() => {
                                                        setActiveContent(null);
                                                        setAiAssistant(false);
                                                    }}></i>
                                                </div>

                                                <p>Unlock seamless email generation for maximum productivity and impact.</p>
                                                <InputTextarea
                                                    placeholder="Type..."
                                                    rows={10}
                                                    cols={40}
                                                    value={emailPrompt}
                                                    onChange={handleEmailValue} />
                                                <Button className="mb-4 mt-3" size="small" label="Generate" icon="pi pi-check" loading={generateLoading} onClick={handleGenerateEmail} disabled={!emailPrompt} />


                                                <div className="chooseSubjectBodyLine" style={{ display: generatedEmail.length > 0 ? 'block' : 'none' }}>
                                                    <h6 className="mb-3">Choose subject line</h6>

                                                    {generatedEmailSubject?.map((data, index) => (

                                                        <div className="card choose-card cursor-pointer" onClick={() => handleSelect(index + 1, data)}>
                                                            <div className="card-header">
                                                                <i className={cardSelect === index + 1 ? 'pi pi-stop-circle me-2' : 'pi pi-circle me-2'} style={{ fontSize: '0.8rem' }}></i> Subject Line {index + 1}
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="m-0">{data.replace(/"/g, '')}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <h6 className="mb-3">Choose body</h6>
                                                    {generatedEmail?.map((data, index) => (
                                                        <div className="card cursor-pointer" onClick={() => handleSelectContent(index + 1, data)}>
                                                            <div className="card-header">
                                                                <i className={contentCardSelect == index + 1 ? 'pi pi-stop-circle me-2' : 'pi pi-circle me-2'} style={{ fontSize: '0.8rem' }}></i> Body {index + 1}
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="m-0">
                                                                    <p><div dangerouslySetInnerHTML={{ __html: data }}></div></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        errMessage &&
                                        <div className="error-message">
                                            <span >{errMessage}</span>
                                        </div>
                                    }
                                </div>
                            )}
                            {activeContent === 'exportContent' && (
                                <div className="col-12 mt-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="content-div">
                                                <div className="d-flex justify-content-between">
                                                    <h5>Rephrase Selection Content</h5>
                                                    <i className="pi pi-times cursor-pointer" onClick={() => {
                                                        setActiveContent(null);
                                                        setAiAssistant(false);
                                                    }}></i>
                                                </div>
                                                <div className="RephraseSelection mb-4">
                                                    <p>{(selectedText == undefined || selectedText == '') ? 'Highlight a portion of text in the email body and select an option below' : selectedText}</p>
                                                </div>
                                                <div className="d-flex justify-content-between mb-3">
                                                    <h6>Give the AI some instruction</h6>
                                                    {/* <span className="text-primary">Show More</span> */}
                                                </div>
                                                <div className="mb-3">
                                                    {rephraseSuggestion?.map((items) => (
                                                        <Button
                                                            label={items.text}
                                                            value={items.text}
                                                            className="auto-suggestion-button me-3 mb-2"
                                                            onClick={() => handleRephraseText(items.text)}
                                                            disabled={(selectedText == undefined || selectedText == '')}
                                                        />

                                                    ))}

                                                </div>
                                                <InputTextarea className="mb-4"
                                                    rows={4}
                                                    cols={50}
                                                    value={rephrasePrompt}
                                                    onChange={handleRephraseValue} />
                                                <Button size="small" label="Rephrase text" icon="pi pi-check" loading={generateLoading} onClick={() => handleRephraseText(rephrasePrompt)} disabled={rephrasePrompt == undefined || selectedText == undefined || selectedText == ''} />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        errMessage &&
                                        <div className="error-message">
                                            <span >{errMessage}</span>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            </Container>
        </div>
    );
};

export default AddTemplate;