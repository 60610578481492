import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { Check } from "feather-icons-react/build/IconComponents";
import "./PricingSection.css";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

// STRIPE_SECRET_KEY

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // Use your actual publishable key

const PricingSection = () => {
    const { API_CHECKOUT_SESSION, API_PLANS } = ApiListFile()
    const [plans, setplans] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [loadingIndex, setLoadingIndex] = useState(null);
    const handleSubscribe = async (priceId,index) => {
        setLoadingIndex(index);
        setisLoading(true)
        const stripe = await stripePromise;
        try {
            // Call your backend to create the checkout session
            const session = await fetchMethod(
                'POST', `${API_CHECKOUT_SESSION}`,
                { price_id: priceId }
            );

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.sessionId,
            });

            if (result.error) {
                alert(result.error.message);
            }
            setisLoading(false)
        } catch (error) {
            console.error('Error subscribing:', error);
            alert('An error occurred. Please try again.');
            setisLoading(false)
        }
    };

    useEffect(() => {
        fetchPlans()
    }, [])


    const fetchPlans = () => {
        fetchMethod('GET', API_PLANS, {})
            .then((data) => {
                console.log("plans== ", data)
                setplans(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    console.log("plans ", plans)



    return (
        <div className="content-page pricingMain">
            <div className="content pt-5">
                <Container>
                    <div className="row">
                        {plans.map((plan, idx) => (
                            <div key={idx} className="col-md-3 mb-4">
                                <div className="card h-100 shadow-lg">
                                    <div className="card-body">
                                        <div className="text-center pricing-heading p-3">
                                            <h4 className="card-title">{plan.name}</h4>
                                            <p>Per User</p>
                                            <br />
                                            {plan.price !== 'Customised' ? (
                                                <>
                                                    <span className="h2">${plan.price}</span>/month
                                                    <br />
                                                    {plan.pricing && (
                                                        <p>
                                                            Yearly: ${plan.pricing.yearly} ({plan.pricing.savings})
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <span className="h2">{plan.price}</span>
                                            )}
                                        </div>
                                        <div>
                                            <ul>
                                                {plan.content.map((item, index) => (
                                                    <li key={index}>
                                                        <span className="h4">. </span>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="card-body text-center">
                                        {plan.price_id && (
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                                <button
                                                    className="btn btn-outline-primary btn-lg"
                                                    style={{
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: '8px',
                                                        minWidth: '150px',
                                                    }}
                                                    onClick={() => handleSubscribe(plan.price_id, idx)}
                                                    disabled={loadingIndex === idx} // Disable only the selected card
                                                >
                                                    {loadingIndex === idx ? (
                                                        <ProgressSpinner
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                            strokeWidth="4"
                                                            fill="var(--surface-ground)"
                                                            animationDuration=".9s"
                                                        />
                                                    ) : null}
                                                    <span>{loadingIndex === idx ? 'Loading...' : 'Subscribe'}</span>
                                                </button>
                                            </div>
                                        )}
                                        {plan.name === 'Custom' && (
                                            <button
                                                className="btn btn-outline-secondary btn-lg"
                                                style={{ borderRadius: '30px' }}
                                                disabled
                                            >
                                                Contact Sales
                                            </button>
                                        )}
                                        {plan.name === 'Free' && (
                                            <button
                                                className="btn btn-outline-secondary btn-lg"
                                                disabled
                                                style={{ borderRadius: '30px' }}
                                            >
                                                Current Plan
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default PricingSection;
