import React, { useContext, useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { contextVar } from './ContextVar';
import { useNavigate } from 'react-router-dom';

function PlanUpgradeModal() {
    const { planVisibleModal, setplanVisibleModal, planUpgradeMessage, setplanUpgradeMessage } = useContext(contextVar)

    const navigate = useNavigate()

    const handleClose = () => {
        setplanVisibleModal(false)
        setplanUpgradeMessage('')
    }
    const handleNavigate = () => {
        navigate('/pricing')
        setplanVisibleModal(false)
        setplanUpgradeMessage('')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-left ">
            <h3 className="font-bold white-space-nowrap">Upgrade Plan</h3>
        </div>
    );

    const footerContent = (
        <div>
            {/* <Button label="Ok" icon="pi pi-check" onClick={handleClose} /> */}
            <Button label="Upgrade Plan" icon="pi pi-check" onClick={handleNavigate} />
        </div>
    );
    return (
        <>
            <div className="card flex justify-content-center">
                <Dialog visible={planVisibleModal} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={handleClose}>
                    <p className="m-0">
                        {planUpgradeMessage}
                    </p>
                </Dialog>
            </div>
        </>
    )
}

export default PlanUpgradeModal