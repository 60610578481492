import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Container, Dropdown, NavLink, Navbar, Offcanvas, Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import nexusLogo from '../assets/images/nexusLogo.png';
import logoDBI from '../assets/images/logo-DBI.png';

import "./TopNav.css"
import Cookies from 'js-cookie';
import { contextVar } from '../Components/ContextVar';
import { Skeleton } from 'primereact/skeleton';
import DropdownNotification from './DropdownNotification';
import { Tooltip } from 'primereact/tooltip';

const logout = () => {
    const keyToKeep = 'tourDone';

    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Iterate over keys and remove all except the one to keep
    keys.forEach(key => {
        if (key !== keyToKeep) {
            console.log(key)
            localStorage.removeItem(key);
        }
    });
    Cookies.remove('accessToken', { domain: '.' + process.env.REACT_APP_DOMAIN + '.com' });
    Cookies.remove('refreshToken', { domain: '.' + process.env.REACT_APP_DOMAIN + '.com' });
    window.location.href = 'https://app.' + process.env.REACT_APP_DOMAIN + '.com/'
};

const userDropdown = [
    {
        to: "https://app." + process.env.REACT_APP_DOMAIN + ".com/profile",
        icon: 'user',
        label: "My Profile",
    },
    {
        to: "/pricing",
        icon: 'user',
        label: "Manage Plan",
    }
];
const DropdownUser = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setDropdownOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    const getNameInitials = () => {
        const splitName = name.split(' ');
        // Get the first and last name initials
        const firstNameInitial = splitName[0].charAt(0);
        const lastNameInitial = splitName.length > 1 ? splitName[splitName.length - 1].charAt(0) : '';
        // Combine and return the initials in uppercase
        const initials = firstNameInitial + lastNameInitial;
        return initials.toUpperCase();
    };

    const { name, profilePic, email, userGroup } = useContext(contextVar)
    const initials = getNameInitials();
    return (
        <div className="position-relative">
            <Link
                ref={trigger}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center gap-4"
                to="#"
            >
                <div style={{
                    height: "50px",
                    width: "50px",
                    backgroundColor: 'orange',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    fontSize: '20px'
                }}>
                    <span>{initials}</span>
                </div>
            </Link>

            {/* <!-- Dropdown Start --> */}
            <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`dropdown-menu p-0 shadow-sm ${dropdownOpen === true ? "show" : ""}`}
                style={{ right: "0", marginTop: "10px", width: "15rem" }}
            >
                <div className="border-bottom heading-profile-name">
                    <h5 className="font-weight-medium mb-0 ">{name}</h5>
                    <h6 className="d-block text-muted small">{email}</h6>
                </div>
                <ul className="list-unstyled p-3 m-0">
                    {userDropdown.map((item) => (
                        <li key={item.label} className="text-nowrap mb-2">
                            <Link
                                to={item.to}
                                className="d-flex align-items-center text-dark text-decoration-none profile-menu-list"
                            >
                                <span><FeatherIcon icon={item.icon} size={15} /> </span>
                                <h6 className="ms-2">{item.label}</h6>
                            </Link>
                        </li>
                    ))}
                    {

                        <li onClick={logout} className="text-nowrap mb-2 d-flex align-items-center text-dark text-decoration-none profile-menu-list" style={{ "cursor": "pointer" }}>
                            <span><FeatherIcon icon='log-out' size={15} /> </span>
                            <h6 className="ms-2">Logout</h6>
                        </li>

                    }
                </ul>
            </div>
            {/* <!-- Dropdown End --> */}
        </div>
    );
};
function TopNavbar() {

    const { name, profilePic, userGroup, userCredential } = useContext(contextVar)
    const planStatus = userCredential?.plan_status;
    const location = useLocation();
    function SequencesNavLink() {
        const location = useLocation();

        return (
            <li className={`nav-item ${isActive('/sequences') ? 'active' : ''}`}>
                <Link
                    to="/sequences"
                    className={location.pathname === "/sequences" || location.pathname.startsWith("/sequence_details/") ? "nav-link active" : "nav-link"}
                >
                    <FeatherIcon icon="send" size={17} />
                    Sequences
                </Link>
            </li>
        );
    }


    const LogoutButton = () => {
        const navigate = useNavigate();

        const logout = () => {
            const keyToKeep = 'tourDone';

            // Get all keys from localStorage
            const keys = Object.keys(localStorage);

            // Iterate over keys and remove all except the one to keep
            keys.forEach(key => {
                if (key !== keyToKeep) {
                    console.log(key)
                    localStorage.removeItem(key);
                }
            });
            Cookies.remove('accessToken', { domain: '.' + process.env.REACT_APP_DOMAIN + '.com' });
            Cookies.remove('refreshToken', { domain: '.' + process.env.REACT_APP_DOMAIN + '.com' });
            window.location.href = 'https://app.' + process.env.REACT_APP_DOMAIN + '.com/'
        };

        return (
            <Dropdown.Item onClick={logout} href="#" className="notify-item">
                <i className="pi pi-power-off dropdown-setting-i me-3"></i>
                <span className='dropdown-setting-text'>Logout</span>
            </Dropdown.Item>
        )
    }

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const getNameInitials = () => {
        const splitName = name.split(' ');
        const initials = splitName[0].charAt(0) + (splitName.length > 1 ? splitName[splitName.length - 1].charAt(0) : '');
        return initials.toUpperCase();
    }
    const isActive = (path) => location.pathname === path;


    return (
        <>
            <div className="navbar-custom">
                <div className="logo-box">
                    <Link to="/" className="logo logo-dark">

                        {localStorage.getItem('logged_in_through_nexus') === 'true' ? (<><span className="logo-sm">
                            <img src={logoDBI} alt="" className='mainHeaderLogo' />
                        </span>
                            <span className="logo-lg">
                                <img src={logoDBI} alt="" className='mainHeaderLogo' />
                            </span></>) : (<><span className="logo-sm">
                                <img src={logoDBI} alt="" className='mainHeaderLogo' />
                            </span>
                                <span className="logo-lg">
                                    <img src={logoDBI} alt="" className='mainHeaderLogo' />
                                </span></>)}

                    </Link>
                </div>
                <div className="topnav">
                    <Navbar expand="lg" className="topnav-menu navbar-expand-lg topnav-main">
                        <div className='menu-horizontal'>
                            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
                            <Navbar.Offcanvas id="offcanvasNavbar-expand-lg" aria-labelledby="offcanvasNavbarLabel-expand-lg" placement="start" >

                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg"> Menu </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className='topnav-main-body'>
                                    <ul className="navbar-nav mr-auto">

                                        {userGroup && userGroup?.dashboard_stats?.dashboard_stats && userGroup?.dashboard_stats?.dashboard_stats.R ?
                                            (
                                                <li className={`nav-item ${isActive('/') ? 'active' : ''}`} id="analytics-tab">
                                                    <Link to="/" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Analytics
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }

                                        {userGroup && userGroup?.template?.get_all_templates && userGroup?.template?.get_all_templates.R ?
                                            (
                                                <li className={`nav-item ${isActive('/templates') ? 'active' : ''}`} id="templates-tab">
                                                    <Link to="/templates" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Template
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }

                                        {userGroup && userGroup?.group?.get_all_groups && userGroup?.group?.get_all_groups.R ?
                                            (
                                                <li className={`nav-item ${isActive('/groups') ? 'active' : ''}`} id="groups-tab">
                                                    <Link to="/groups" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Groups
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }

                                        {userGroup && userGroup?.recipient?.get_all_recipients && userGroup?.recipient?.get_all_recipients.R ?
                                            (
                                                <li className={`nav-item ${isActive('/leads') ? 'active' : ''}`} id="recipients-tab">
                                                    <Link to="/leads" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Lead
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }
                                        {/* {localStorage.getItem('custom_features') == "true" && localStorage.getItem('logged_in_through_nexus') === 'false' && <li className={`nav-item ${isActive('/prospect') ? 'active' : ''}`}>
                                            <Link to="/prospect" className="nav-link" id="prospect-tab">
                                                <FeatherIcon icon="user" size={17} /> 
                                                Prospect
                                            </Link>
                                        </li>} */}
                                        {userGroup && userGroup?.sequence?.get_sequences && userGroup?.sequence?.get_sequences.R ?
                                            <li className="nav-item" id="sequences-tab">
                                                <SequencesNavLink />
                                            </li>
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }

                                        {userGroup && userGroup?.task?.get_paginated_pending_task && userGroup?.task?.get_paginated_pending_task.R ?
                                            (
                                                <li className={`nav-item ${isActive('/tasks') ? 'active' : ''}`} id="tasks-tab">
                                                    <Link to="/tasks" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Task
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }

                                        {/* <li className={`nav-item ${isActive('/inbox') ? 'active' : ''}`} id="inbox-tab">
                                            <Link to="/inbox" className="nav-link" >
                                                <FeatherIcon icon="mail" size={17} /> 
                                                Inbox
                                            </Link>
                                        </li> */}
                                        {planStatus === 'Free' || !planStatus || planStatus === 'Basic' ? (
                                          <>
                                          <li
                                            className="nav-item disabled"
                                            id="calls-tab"
                                            style={{ opacity: 0.5, cursor: 'not-allowed' }}
                                            data-pr-tooltip="Upgrade your plan to access the Calls feature"
                                          >
                                            <span className="nav-link">
                                              <FeatherIcon icon="at-sign" size={17} />
                                              Calls
                                            </span>
                                          </li>
                                          <Tooltip target="#calls-tab" content="Upgrade your plan to access the Calls feature" />
                                        </>
                                        ) : (
                                            <>
                                                {userGroup && userGroup?.twilio?.get_call_list && userGroup?.twilio?.get_call_list.R ? (
                                                    <li className={`nav-item ${isActive('/calls') ? 'active' : ''}`} id="calls-tab">
                                                        <Link to="/calls" className="nav-link">
                                                            <FeatherIcon icon="at-sign" size={17} />
                                                            Calls
                                                        </Link>
                                                    </li>
                                                ) : (
                                                    <li className="mt-4 me-2">
                                                        <Skeleton width="100px" height="30px" />
                                                    </li>
                                                )}
                                            </>
                                        )}
                                        {userGroup && userGroup?.account_info ?
                                            (
                                                <li className={`nav-item ${isActive('/reports') ? 'active' : ''}`} id="reports-tab">
                                                    <Link to="/reports" className="nav-link" >
                                                        <FeatherIcon icon="user" size={17} />
                                                        Reports
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }
                                        {/* {localStorage.getItem('custom_features') == "true" && <li className={`nav-item ${isActive('/sales-execution-report') ? 'active' : ''}`}>
                                            <Link to="/sales-execution-report" className="nav-link">
                                                <FeatherIcon icon="trello" size={17} /> 
                                                Sales Execution Report
                                            </Link>
                                        </li>} */}

                                        {userGroup && userGroup?.account_info ?
                                            (
                                                <li className={`nav-item ${isActive('/settings/accountinfo') ? 'active' : ''}`} id="configuration-tab">
                                                    <Link to="/settings/accountinfo" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Configuration
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        }
                                         {/* {userGroup && userGroup?.account_info ?
                                            (
                                                <li className={`nav-item ${isActive('/sales-execution-report') ? 'active' : ''}`} id="execution-tab">
                                                    <Link to="/sales-execution-report" className="nav-link">
                                                        <FeatherIcon icon="at-sign" size={17} />
                                                        Sales Execution Report
                                                    </Link>
                                                </li>
                                            )
                                            :
                                            <li className='mt-4 me-2'><Skeleton width='100px' height='30px'></Skeleton></li>
                                        } */}

                                    </ul>
                                    <div className='header-end'>
                                        <DropdownUser />

                                    </div>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </div>
                    </Navbar>
                </div>
                <ul className="logo-box list-unstyled profile-end-nav topnav-menu float-end mb-0" style={{marginTop:'12px'}}>
                    {/* <DropdownNotification/> */}
                    <DropdownUser />
                </ul>
            </div>

        </>
    )
}

export default TopNavbar